import React from "react";
import _ from "i18n";
import { SCOPES } from "config";
import { useSelector } from "react-redux";
import Switch from "components/Commun/Switch/Switch";
import ClientSelect from "components/Library/ClientSelect";
import GroupsSelect from "components/Library/GroupsSelect";
import ContactSelect from "components/Library/ContactSelect";
import CollaboratorsSelect from "components/Library/CollaboratorsSelect";

import styles from "./styles.module.scss";

const Scope = ({
  checkedItems,
  setCheckedItems,
  specCollaborators,
  setSpecCollaborators,
  specClients,
  setSpecClients,
  specContacts,
  setSpecContacts,
  groups,
  setGroups,
}) => {
  const auth = useSelector((state) => state.auth);

  const handleChange = (scope) => {
    let tab = [];
    switch (scope) {
      case "PUBLIC":
        tab = ["PUBLIC"];
        setSpecCollaborators([]);
        setSpecClients([]);
        setSpecContacts([]);
        setGroups([]);
        break;
      case "ALL_COLLABORATORS":
        if (checkedItems.includes("ALL_COLLABORATORS")) {
          tab = [...checkedItems.filter((el) => el !== "ALL_COLLABORATORS")];
        } else {
          tab = [
            ...checkedItems.filter(
              (el) => el !== "PUBLIC" && el !== "SPEC_COLLABORATOR"
            ),
            "ALL_COLLABORATORS",
          ];
          setSpecCollaborators([]);
        }
        break;
      case "ALL_CLIENTS":
        if (checkedItems.includes("ALL_CLIENTS")) {
          tab = [...checkedItems.filter((el) => el !== "ALL_CLIENTS")];
        } else {
          tab = [
            ...checkedItems.filter(
              (el) => el !== "PUBLIC" && el !== "SPEC_CLIENT"
            ),
            "ALL_CLIENTS",
          ];
          setSpecClients([]);
        }
        break;
      case "ALL_CONTACTS":
        if (checkedItems.includes("ALL_CONTACTS")) {
          tab = [...checkedItems.filter((el) => el !== "ALL_CONTACTS")];
        } else {
          tab = [
            ...checkedItems.filter(
              (el) => el !== "PUBLIC" && el !== "SPEC_CONTACT"
            ),
            "ALL_CONTACTS",
          ];
          setSpecContacts([]);
        }
        break;
      case "SPEC_COLLABORATOR":
        if (checkedItems.includes("SPEC_COLLABORATOR")) {
          tab = [...checkedItems.filter((el) => el !== "SPEC_COLLABORATOR")];
          setSpecCollaborators([]);
        } else {
          tab = [
            ...checkedItems.filter(
              (el) => el !== "PUBLIC" && el !== "ALL_COLLABORATORS"
            ),
            "SPEC_COLLABORATOR",
          ];
        }
        break;
      case "SPEC_CLIENT":
        if (checkedItems.includes("SPEC_CLIENT")) {
          tab = [...checkedItems.filter((el) => el !== "SPEC_CLIENT")];
          setSpecClients([]);
        } else {
          tab = [
            ...checkedItems.filter(
              (el) => el !== "PUBLIC" && el !== "ALL_CLIENTS"
            ),
            "SPEC_CLIENT",
          ];
        }
        break;
      case "SPEC_CONTACT":
        if (checkedItems.includes("SPEC_CONTACT")) {
          tab = [...checkedItems.filter((el) => el !== "SPEC_CONTACT")];
          setSpecContacts([]);
        } else {
          tab = [
            ...checkedItems.filter(
              (el) => el !== "PUBLIC" && el !== "ALL_CONTACTS"
            ),
            "SPEC_CONTACT",
          ];
        }
        break;
      case "GROUP":
        if (checkedItems.includes("GROUP")) {
          tab = [...checkedItems.filter((el) => el !== "GROUP")];
          setGroups([]);
        } else {
          tab = [
            ...checkedItems.filter((el) => el !== "PUBLIC" && el !== "GROUP"),
            "GROUP",
          ];
        }
        break;
      default:
        break;
    }

    if (tab.length === 0) {
      tab = ["PUBLIC"];
    }
    setCheckedItems(tab);
  };

  return (
    <ul className={styles.switches}>
      {SCOPES.map((scope, i) => (
        <li key={`scope-${i}`}>
          <div className={styles.switch}>
            <span>{_(scope)}</span>
            <Switch
              checked={checkedItems.includes(scope)}
              onChange={() => handleChange(scope)}
            />
          </div>
          {scope === "SPEC_COLLABORATOR" &&
            checkedItems.includes("SPEC_COLLABORATOR") && (
              <div className={styles.select}>
                <CollaboratorsSelect
                  isMulti={true}
                  selectedCollaborators={specCollaborators}
                  onChange={setSpecCollaborators}
                  organizationId={auth.navCommunity.id}
                />
              </div>
            )}
          {scope === "SPEC_CLIENT" && checkedItems.includes("SPEC_CLIENT") && (
            <div className={styles.select}>
              <ClientSelect
                isMulti={true}
                selectedClients={specClients}
                onChange={setSpecClients}
              />
            </div>
          )}
          {scope === "SPEC_CONTACT" &&
            checkedItems.includes("SPEC_CONTACT") && (
              <div className={styles.select}>
                <ContactSelect
                  isMulti={true}
                  selectedContacts={specContacts}
                  onChange={setSpecContacts}
                  organizationId={auth.navCommunity.id}
                />
              </div>
            )}
          {scope === "GROUP" && checkedItems.includes("GROUP") && (
            <div className={styles.select}>
              <GroupsSelect
                isMulti={true}
                selectedGroups={groups}
                onChange={setGroups}
                organizationId={auth.navCommunity.id}
              />
            </div>
          )}
        </li>
      ))}
    </ul>
  );
};

export default Scope;
