import React, { useState } from "react";
import Tab from "./Tab";
import classnames from "classnames";
import styles from "./Tabs.module.scss";

function Tabs({ children, theme, onClickTab }) {
  const [activeTab, setActiveTab] = useState(0);

  const onClickTabItem = (tab) => {
    setActiveTab(tab);
    if (onClickTab) {
      onClickTab(tab);
    }
  };

  if (children.length > 0) {
    return (
      <div className={classnames(styles.tabs)}>
        <ol className={styles["tab-list"]}>
          {children.map((child, i) => {
            const { label, icon } = child.props;
            return (
              <Tab indexTab={i} activeTab={activeTab} icon={icon} key={label} label={label} onClick={onClickTabItem} />
            );
          })}
        </ol>
        <div className={styles["tab-content"]}>
          {children.map((child, index) => {
            const { label } = child.props;
            return (
              <div className={index !== activeTab ? styles["tab-hidden"] : ""} key={`content-${label}`}>
                {child.props.children}
              </div>
            );
          })}
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default Tabs;
