import { configureStore, combineReducers } from "@reduxjs/toolkit";

import authSlice from "./auth";
import paramsSlice from "./params";

export const store = configureStore({
  reducer: combineReducers({
    auth: authSlice,
    params: paramsSlice
  }),
});
