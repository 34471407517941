import React, { useEffect, useState } from "react";
import Select from "react-select";
import { SELECT_STYLES } from "config";
import { useSelector } from "react-redux";
import _ from "i18n";

const SelectPage = ({ pages, setPages, themeObject }) => {
  const { language } = useSelector((state) => state.params);
  const [pagesOptions, setPagesOptions] = useState([]);
  // const [showPopOver, setShowPopOver] = useState(false);

  const titleAttr = `title${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;

  useEffect(() => {
    if (themeObject) {
      setPagesOptions(
        themeObject.pages.map((c) => {
          const themeTitle =
            c[titleAttr] || c["titleFr"] || c["titleEn"] || c["titleNl"];
          return {
            id: c.id,
            title: themeTitle,
          };
        })
      );
    }
  }, [themeObject, titleAttr]);

  const handleChangePages = (pp) => {
    console.log(pp);
  };

  return (
    // <div
    //   className={`${styles.inputContainer} ${showPopOver ? styles.active : ""}`}
    // >
    <Select
      classNamePrefix="react-select"
      styles={SELECT_STYLES}
      options={pagesOptions}
      placeholder={_("select_page")}
      value={pages}
      // className={styles.input}
      getOptionLabel={(option) => option.title}
      getOptionValue={(option) => option.id}
      onChange={setPages}
      isMulti
      // isDisabled={!themeObject || !pagesOptions}
    />
    // </div>
  );
};
export default SelectPage;
