import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import classnames from "classnames";
import _ from "../../i18n";

import {
  getlibraryWebtools,
  getSharedWebtools,
  saveBannedWebtool,
} from "../../api";
import { CardTool, ModalConfirm } from "tamtam-components";
import FilterIcon from "../Icons/FilterIcon";
import IconClose from "../Icons/IconClose";
import { SCOPES } from "../../config";

import "./LibraryPage.local.scss";
import { useNavigate } from "react-router-dom";
import IconTrash from "components/Icons/IconTrash";
import IconRefresh from "components/Icons/IconRefresh";
import ArrowBackIcon from "components/Icons/ArrowBackIcon";

function LibraryPage() {
  const { user, token, navCommunity } = useSelector((state) => state.auth);
  const params = useSelector((state) => state.params);
  const navigate = useNavigate();

  if (
    user &&
    (user.role.typeStatus !== "MANAGER" ||
      user.role.type !== "LEGAL_REPRESENTATIVE")
  ) {
    navigate("/");
  }

  const [libraryWebtools, setLibraryWebtools] = useState([]);
  const [sharedWebtools, setSharedWebtools] = useState([]);

  const [filtredLibraryWebtools, setFiltredLibraryWebtools] = useState([]);
  const [filtredSharedWebtools, setFiltredSharedWebtools] = useState([]);
  const [organizationtags, setOrganizationTags] = useState([]);
  const [visibleScopeSelect, setVisibleScopeSelect] = useState(false);
  const [loadingUnsharing, setLoadingUnsharing] = useState(false);
  const [unsharedItem, setUnsharedItem] = useState(null);
  const [tooltipIsVisible, setVisibilityTooltip] = useState(null);

  const [sharedFilter, setSharedFilter] = useState({
    scopes: [],
    lng: params.language,
    keyWord: "",
  });

  const [libraryFilter, setLibraryFilter] = useState({
    lng: params.language,
    organization: { name: _("all"), id: null },
    keyWord: "",
  });
  const tagsScopesElement = document.getElementsByClassName("select_tag");

  const libraryLanguage =
    libraryFilter.lng.charAt(0).toUpperCase() + libraryFilter.lng.slice(1);

  const sharedLanguage =
    sharedFilter.lng.charAt(0).toUpperCase() + sharedFilter.lng.slice(1);

  let libraryQuery = useQuery(
    ["getLibraryWebtools", token, navCommunity],
    async () => {
      if (token && navCommunity) {
        const webtools = await getlibraryWebtools({
          token: token,
          community: navCommunity.id,
        })
          .then((res) => {
            return res.data.data ? res.data.data : [];
          })
          .catch((error) => {
            return [];
          });
        const lang =
          libraryFilter.lng.charAt(0).toUpperCase() +
          libraryFilter.lng.slice(1);
        const arr = webtools
          .filter((w) => w[`title${lang}`]?.length > 0)
          .map((w) => w.organization);
        const organizations = arr.filter(
          (x, i) =>
            arr.findIndex((object) => {
              return object.id === x.id;
            }) === i
        );
        setOrganizationTags(organizations);
        return webtools;
      }
    }
  );

  useEffect(() => {
    if (libraryQuery.data) {
      const webtools = libraryQuery.data.filter((webtool) => {
        let toReturn = true;
        if (libraryFilter.organization.id) {
          toReturn = webtool.organization.id === libraryFilter.organization.id;
        }
        if (toReturn && libraryFilter.keyWord) {
          toReturn = webtool[`title${libraryLanguage}`]
            .toLowerCase()
            .includes(libraryFilter.keyWord.toLowerCase());
        }
        if (toReturn && libraryFilter.lng) {
          toReturn = webtool.languages.includes(
            libraryFilter.lng.toUpperCase()
          );
        }
        return toReturn;
      });
      const lang =
        libraryFilter.lng.charAt(0).toUpperCase() + libraryFilter.lng.slice(1);
      const arr = webtools
        .filter((w) => w[`title${lang}`]?.length > 0)
        .map((w) => w.organization);
      const organizations = arr.filter(
        (x, i) =>
          arr.findIndex((object) => {
            return object.id === x.id;
          }) === i
      );
      setOrganizationTags(organizations);
      setLibraryWebtools(webtools);
    }
  }, [libraryQuery.data, libraryFilter]);

  let sharedQuery = useQuery(
    ["getSharedWebtools", token, navCommunity],
    async () => {
      if (token && navCommunity) {
        const webtools = await getSharedWebtools({
          token: token,
          community: navCommunity.id,
        })
          .then((res) => {
            return res.data.data ? res.data.data : [];
          })
          .catch((error) => {
            return [];
          });

        // const lang =
        //   sharedFilter.lng.charAt(0).toUpperCase() + libraryFilter.lng.slice(1);

        // setSharedWebtools(result);
        // setFiltredSharedWebtools(result);
        return webtools;
      }
    }
  );

  useEffect(() => {
    if (sharedQuery.data) {
      const webtools = sharedQuery.data.filter((webtool) => {
        let toReturn = true;
        if (sharedFilter.scopes.length > 0) {
          let found = false;
          sharedFilter.scopes.forEach((element) => {
            found = found || webtool.csScope.split(",").includes(element);
          });
          toReturn = found;
        }
        if (toReturn && sharedFilter.keyWord) {
          toReturn = webtool[`title${libraryLanguage}`]
            .toLowerCase()
            .includes(sharedFilter.keyWord.toLowerCase());
        }
        if (toReturn && sharedFilter.lng) {
          toReturn = webtool.languages.includes(sharedFilter.lng.toUpperCase());
        }
        return toReturn;
      });
      setSharedWebtools(webtools);
    }
  }, [sharedQuery.data, sharedFilter]);

  // useEffect(() => {
  //   if (sharedQuery.isFetched && libraryQuery.isFetched) {
  //     let sharedIds = sharedWebtools.map((item) => item.id);
  //     let filtredResult = libraryWebtools.filter(
  //       (item) => !sharedIds.includes(item.id)
  //     );
  //     setLibraryWebtools(filtredResult);
  //     setFiltredLibraryWebtools(filtredResult);
  //   }
  // }, [sharedQuery.isFetched, libraryQuery.isFetched]);

  // useEffect(() => {
  //   let result = sharedWebtools.filter((webtool) => {
  //     let toReturn = true;
  //     if (sharedFilter.scopes.length > 0) {
  //       let found = false;
  //       sharedFilter.scopes.forEach((element) => {
  //         found = found || webtool.csScope.split(",").includes(element);
  //       });
  //       toReturn = found;
  //     }
  //     if (toReturn && !sharedFilter.keyWord) {
  //       toReturn = webtool[`title${sharedLanguage}`]
  //         .toLowerCase()
  //         .includes(sharedFilter.keyWord.toLowerCase());
  //     }
  //     if (toReturn && sharedFilter.lng) {
  //       toReturn = webtool.languages.includes(sharedFilter.lng.toUpperCase());
  //     }
  //     return toReturn;
  //   });
  //   setFiltredSharedWebtools(result);
  // }, [sharedFilter]);

  // const prepareOrganizationTags = (data) => {
  //   let result = [{ name: _("all"), value: null }];
  //   let organizationIds = [];
  //   data.forEach((element) => {
  //     if (!organizationIds.includes(element.organization.id)) {
  //       result.push({
  //         name: element.organization.name,
  //         value: element.organization.id,
  //       });
  //       organizationIds.push(element.organization.id);
  //     }
  //   });
  //   setOrganizationTags(result);
  // };

  const updateLanguageLibraryFilter = (lng) => {
    setLibraryFilter((previousState) => {
      return { ...previousState, lng };
    });
  };

  const updateOrganizationLibraryFilter = (organization) => {
    setLibraryFilter((previousState) => {
      return { ...previousState, organization };
    });
  };

  const updateKeyWordLibraryFilter = (word) => {
    setLibraryFilter((previousState) => {
      return { ...previousState, keyWord: word.target.value };
    });
  };

  const updateLanguageSharedFilter = (lng) => {
    setSharedFilter((previousState) => {
      return { ...previousState, lng };
    });
  };

  const updateKeyWordSharedFilter = (word) => {
    setSharedFilter((previousState) => {
      return { ...previousState, keyWord: word.target.value };
    });
  };

  const updateScopeSharedFilter = (scope) => {
    let scopes = [];
    if (sharedFilter.scopes.includes(scope)) {
      scopes = sharedFilter.scopes.filter((item) => item !== scope);
    } else {
      scopes = [...sharedFilter.scopes];
      scopes.push(scope);
    }
    setSharedFilter((previousState) => {
      return { ...previousState, scopes };
    });
    setVisibleScopeSelect(false);
  };

  const renderTagLabel = (item) => {
    switch (item) {
      case "PUBLIC":
        return "public";
      case "ALL_CLIENTS":
        return "clients";
      case "ALL_COLLABORATORS":
        return "collaborators";
      case "ALL_GROUPS":
        return "groups";
      default:
        return "public";
    }
  };
  // const renderPageHeader = () => {
  //   return (
  //     <div className="page_header">
  //       <div>
  //         <div className="page_header_icon">
  //           <BrowserIcon />
  //         </div>
  //         <div className="page_header_info">
  //           <div>
  //             <h3>{_("library")}</h3>
  //             <p>{_("subHeaderLibrary")}</p>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const cardsFetching = (number) => {
    let cards = [];
    for (let j = 0; j < number; j++) {
      cards.push(
        <div className="small-4 medium-4 large-4 grid_card">
          <div key={`fetch-${j}`} className="card_tool_fetching gradiant" />
        </div>
      );
    }
    return cards;
  };

  const renderLeftWebToolGrid = () => {
    // const arr = webToolsInit
    //   ? webToolsInit
    //       .filter(
    //         (w) => w[`title${lng}`]?.length > 0
    //         // filter.categoryId
    //         //   ? w[`title${lng}`]?.length > 0 && w.category.id === filter.categoryId
    //         //   : w[`title${lng}`]?.length > 0
    //       )
    //       .map((w) => w.category)
    //   : [];
    // const filteredCategories = arr.filter(
    //   (x, i) =>
    //     arr.findIndex((object) => {
    //       return object.id === x.id;
    //     }) === i
    // );

    return (
      <div className="cell small-12 large-9 library_frame">
        <h2>{_("webtools_shared_by_colleague")}</h2>
        <div className="library_frame_filter grid-x">
          <div className="tags_frame grid-x align-center">
            <div
              className={classnames(
                "tag_item",
                libraryFilter.organization.id === null ? "activated" : ""
              )}
              onClick={() =>
                updateOrganizationLibraryFilter({ name: _("all"), id: null })
              }
            >
              {_("all")}
            </div>
            {organizationtags.map((item, i) => {
              return (
                <div
                  className={classnames(
                    "tag_item",
                    libraryFilter.organization.id === item.id ? "activated" : ""
                  )}
                  onClick={() => updateOrganizationLibraryFilter(item)}
                >
                  {item.name}
                </div>
              );
            })}
          </div>
          <div className="search_frame grid-x align-center">
            {["fr", "en", "nl"].map((item, i) => {
              return (
                <div
                  className={classnames(
                    "lng_item",
                    libraryFilter.lng === item ? "activated" : ""
                  )}
                  onClick={() => updateLanguageLibraryFilter(item)}
                >
                  {item}
                </div>
              );
            })}
            <div className="search cell small-12 large-7">
              <i className="icon icon-magnifier" />
              <input
                id="searchInput"
                autoComplete="off"
                type="text"
                placeholder={`${_("search")}...`}
                onChange={(event) => updateKeyWordLibraryFilter(event)}
              />
            </div>
          </div>
        </div>
        <div className="grid-x">
          {libraryQuery.isFetching ? (
            cardsFetching(6)
          ) : libraryWebtools.length === 0 ? (
            <div className="no-data-found">{_("noDataFound")}</div>
          ) : (
            libraryWebtools.map((item, i) => {
              return (
                <div className="small-4 medium-4 large-4 grid_card">
                  <div className="wrap-card">
                    <div
                      className="share-action"
                      onClick={() => {
                        setUnsharedItem(item);
                      }}
                    >
                      <IconTrash size={20} />
                    </div>
                    <CardTool
                      // toolContent={
                      //   <Tooltip
                      //     webtool={item}
                      //     onSavedWebtool={(element) =>
                      //       handleShareWebtool(element)
                      //     }
                      //   />
                      // }
                      displayTooltip={tooltipIsVisible === item.id}
                      setTooltipVisibility={(element) => {
                        setVisibilityTooltip(element ? item.id : null);
                      }}
                      // allowedActions={{ share: true }}
                      data={{
                        id: item.id,
                        icon: item.icon.label,
                        link: item[`urlName${libraryLanguage}`],
                        title: item[`title${libraryLanguage}`],
                        description:
                          item[`description${libraryLanguage}`] ?? "",
                        tag: item.category[`name${libraryLanguage}`],
                        scope: item.csScope.includes("PUBLIC")
                          ? "public"
                          : "shared",
                        created: item.createdAt
                          ? `${_("createdThe")} ${item.createdAt}`
                          : "",
                        organization: item.organization
                          ? `${_("by")} ${item.organization.name.toLowerCase()}`
                          : "",
                      }}
                      color={item.category.colorCode}
                      onReach={() =>
                        window.open(item[`urlName${libraryLanguage}`], "_blank")
                      }
                    />
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    );
  };

  const renderRigthWebToolGrid = () => {
    // let cardsFetching = [];
    // for (let j = 0; j < 6; j++) {
    //   cardsFetching.push(
    //     <div className="small-4 medium-4 large-4 grid_card">
    //       <div key={j} className="card_tool_fetching gradiant" />
    //     </div>
    //   );
    // }
    return (
      <div className="grid-x cell small-12 large-3">
        <div className="private_frame">
          <h2>{_("webtools_rejected")}</h2>
          <div className="library_frame_filter">
            <div className="search_frame grid-x align-center">
              {["fr", "en", "nl"].map((item, i) => {
                return (
                  <div
                    className={classnames(
                      "lng_item",
                      sharedFilter.lng === item ? "activated" : ""
                    )}
                    onClick={() => updateLanguageSharedFilter(item)}
                  >
                    {item}
                  </div>
                );
              })}
              <div className="search cell small-12 large-6">
                <i className="icon icon-magnifier" />
                <input
                  id="searchInput"
                  autoComplete="off"
                  type="text"
                  placeholder={`${_("search")}...`}
                  onChange={(event) => updateKeyWordSharedFilter(event)}
                />
              </div>
            </div>
          </div>
          {sharedQuery.isFetching ? (
            cardsFetching(6)
          ) : sharedWebtools.length === 0 ? (
            <div className="no-data-found">{_("noDataFound")}</div>
          ) : (
            sharedWebtools.map((item, i) => {
              return (
                <div className="small-4 medium-4 large-3 grid_card">
                  <div className="wrap-card add">
                    <div
                      className="share-action"
                      onClick={() => {
                        if (!loadingUnsharing)
                          handleUnShareWebtool("REMOVE", item.id);
                      }}
                    >
                      {loadingUnsharing ? (
                        <IconRefresh size={20} className="ratoting" />
                      ) : (
                        <ArrowBackIcon size={30} />
                      )}
                    </div>
                    <CardTool
                      displayTooltip={tooltipIsVisible === item.id}
                      setTooltipVisibility={(element) => {
                        setVisibilityTooltip(element ? item.id : null);
                      }}
                      scope={item.target}
                      data={{
                        id: item.id,
                        icon: item.icon.label,
                        link: item[`urlName${sharedLanguage}`],
                        title: item[`title${sharedLanguage}`],
                        description:
                          item[`description${libraryLanguage}`] ?? "",
                        tag: item.category[`name${sharedLanguage}`],
                        scope: item.csScope === "PUBLIC" ? "public" : "shared",
                        created: item.createdAt
                          ? `${_("createdThe")} ${item.createdAt}`
                          : "",
                        organization: item.organization
                          ? `${_("by")} ${item.organization.name.toLowerCase()}`
                          : "",
                      }}
                      color={item.category.colorCode}
                      onReach={() =>
                        window.open(item[`urlName${sharedLanguage}`], "_blank")
                      }
                      onUnshare={() => setUnsharedItem(item)}
                    />
                  </div>
                </div>
              );
            })
          )}
        </div>
        <ModalConfirm
          type="delete"
          isOpen={unsharedItem ? true : false}
          onCancel={() => {
            setLoadingUnsharing(false);
            setUnsharedItem(null);
          }}
          onConfirm={() => handleUnShareWebtool("ADD", unsharedItem.id)}
          inProcess={loadingUnsharing}
          title={_("confirmDelete")}
          text={_("confirmDeleteMessage")}
          labelNo={_("no")}
          labelYes={_("yes")}
          labelError={_("error")}
        />
      </div>
    );
  };

  const handleShareWebtool = async (webtool, id) => {
    try {
      setVisibilityTooltip();
      if (!id) libraryQuery.refetch();
      sharedQuery.refetch();

      // let filtredSharedWebtoolsLocal = [];
      // if (id) {
      //   filtredSharedWebtoolsLocal = filtredSharedWebtools.map((item) => {
      //     return item.organizationArticleId === id
      //       ? { ...item, csScope: webtool.csScope }
      //       : item;
      //   });
      // } else {
      //   let filtredLibraryWebtoolsLocal = filtredLibraryWebtools.filter(
      //     (item) => item.id !== webtool.id
      //   );
      //   filtredSharedWebtoolsLocal = [...filtredSharedWebtools];
      //   filtredSharedWebtoolsLocal.unshift({
      //     ...webtool,
      //     sharingDate: moment().format("YYYY-MM-DD HH:mm:ss"),
      //   });
      //   setLibraryWebtools(filtredLibraryWebtoolsLocal);
      // }
      // setFiltredSharedWebtools(filtredSharedWebtoolsLocal);
      // setSharedWebtools(filtredSharedWebtoolsLocal);
      toast.success(_("data_saved_successfully"));
    } catch (e) {
      toast.error(_("savingError"), { autoClose: true });
      return null;
    }
  };

  const handleUnShareWebtool = async (action, id) => {
    setLoadingUnsharing(true);
    try {
      await saveBannedWebtool({
        token: token,
        organizationId: navCommunity.id,
        action: action,
        id: id,
      });
      sharedQuery.refetch();
      libraryQuery.refetch();
      setUnsharedItem(null);
      setLoadingUnsharing(false);
      toast.success(_("data_saved_successfully"));

      // setFiltredSharedWebtools(filtredSharedWebtoolsLocal);
      // let sharedWebtoolsLocal = sharedWebtools.filter(
      //   (item) => item.id !== unsharedItem.id
      // );
      // setSharedWebtools(sharedWebtoolsLocal);

      // delete unsharedItem.organizationArticleId;
      // // setFiltredLibraryWebtools(
      // //   filtredLibraryWebtools
      // //   // {
      // //   //   ...unsharedItem,
      // //   //   csScope: "PUBLIC",
      // //   // },
      // // );
      // setLibraryWebtools([
      //   ...libraryWebtools,
      //   { ...unsharedItem, csScope: "PUBLIC" },
      // ]);

      // setUnsharedItem(null);
      // setLoadingUnsharing(false);
      // toast.success(_("data_saved_successfully"));
    } catch (e) {
      setUnsharedItem(null);
      setLoadingUnsharing(false);
      toast.error(_("savingError"), { autoClose: true });
      return null;
    }
  };
  useEffect(() => {
    if (
      navCommunity &&
      !navCommunity.webtoolsPreferences?.source?.includes("COLLEAGUE")
    ) {
      window.location.href = "/";
    }
  }, [navCommunity]);

  return (
    <div className="align-center medium-11">
      <Fragment>
        {/* {renderPageHeader()} */}
        <div className="grid-x page_content">
          {renderLeftWebToolGrid()}
          {renderRigthWebToolGrid()}
        </div>
      </Fragment>
    </div>
  );
}

export default LibraryPage;
