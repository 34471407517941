import React from "react";

import classnames from "classnames";
import styles from "./Tabs.module.scss";

function Tab({ activeTab, indexTab, label, icon, onClick }) {
  const onClickTabItem = () => {
    onClick(indexTab);
  };

  return (
    <li
      className={classnames(styles["tab-list-item"], activeTab === indexTab ? styles["tab-list-active"] : "")}
      onClick={onClickTabItem}
    >
      <div>
        {icon ? <img src={icon} alt={label} /> : null}
        <span className={styles["tab-btn"]}>{label}</span>
      </div>
    </li>
  );
}

export default Tab;
