import React, { useState } from "react";
import Select from "react-select";

import { getThemes } from "api";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { SELECT_STYLES } from "config";
import _ from "i18n";

const SelectTheme = ({ theme, setTheme, pages, setPages, setThemeObject }) => {
  const { token } = useSelector((state) => state.auth);
  const { language } = useSelector((state) => state.params);

  const [start, setStart] = useState(0);
  const [themes, setThemes] = useState([]);
  const [pagesOptions, setPagesOptions] = useState([]);

  const titleAttr = `title${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;

  const { isFetching: loadingThemes } = useQuery(
    ["getThemes", token, start],
    async () => {
      if (token) {
        try {
          const res = await getThemes({ token, start });
          setThemes([...themes, ...res.data.data]);
        } catch (error) {
          console.error(error);
        }
      }
    }
  );

  // const customFilter = (option, searchText) => {
  //   return option.data.title.toLowerCase().includes(searchText.toLowerCase());
  // };

  const handleChangeTheme = (ele) => {
    const theme = themes.filter((t) => t.id === ele.id);
    setTheme(ele);
    setThemeObject(theme[0]);
    setPages([]);
    // setPagesOptions(
    //   theme[0].pages.map((c) => {
    //     const themeTitle =
    //       c[titleAttr] || c["titleFr"] || c["titleEn"] || c["titleNl"];
    //     return {
    //       id: c.id,
    //       title: themeTitle,
    //     };
    //   })
    // );
  };

  // const onChangeSelectTheme = (id) => {
  //   const theme = themesQuery.data.filter((t) => t.id === id);
  //   theme.length === 1 ? setPages(theme[0].pages) : setPages([]);
  //   setSelectTheme(id);
  //   setSelectPages([]);
  // };

  return (
    // <div className={`${styles.inputContainer} `}>

    <Select
      classNamePrefix="react-select"
      styles={SELECT_STYLES}
      isLoading={loadingThemes}
      // options={themes}
      // className={styles.input}
      // filterOption={customFilter}
      placeholder={_("select_theme")}
      value={theme}
      getOptionLabel={(option) => option.title}
      getOptionValue={(option) => option.id}
      onChange={handleChangeTheme}
      onMenuScrollToBottom={() => setStart(start + 20)}
      options={themes.map((c) => {
        const themeTitle =
          c[titleAttr] || c["titleFr"] || c["titleEn"] || c["titleNl"];
        return {
          id: c.id,
          title: themeTitle,
        };
      })}
    />
  );
};

export default SelectTheme;
