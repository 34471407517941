import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import _ from "../../i18n";

import {
  getCategories,
  getFavorites,
  getWebtools,
  saveFavorite,
} from "../../api";

import "./Home.local.scss";
import WebtoolFilter from "./WebtoolFilter";
import OrganizationWebtools from "./OrganizationWebtools";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

function Home() {
  const { token, navCommunity, user } = useSelector((state) => state.auth);
  const params = useSelector((state) => state.params);

  const allowedActions =
    user &&
    (user.isUaAdmin ||
      (user.role.typeStatus === "LEGAL_REPRESENTATIVE" &&
        user.role.type === "MANAGER" &&
        user.role.organization.id === navCommunity.id));

  const language =
    params.language.charAt(0).toUpperCase() + params.language.slice(1);

  const [favoriteIds, setFavoriteIds] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loadingFavoriteIds, setLoadingFavoriteIds] = useState([]);
  const [tooltipIsVisible, setVisibilityTooltip] = useState(null);
  const [searchedWebtools, setSearchedWebtools] = useState([]);
  const [allWebtools, setAllWebtools] = useState([]);

  const [orgWebtools, setOrgWebtools] = useState([]);
  const [colleagueWebtools, setColleagueWebtools] = useState([]);
  const [fffWebtools, setFffWebtools] = useState([]);

  const [filter, setFilter] = useState({
    lng: [],
    categoryId: [],
    keyWord: "",
  });

  const [isFetchingCats, setIsFetchingCats] = useState(false);

  // fetch favorites webtools
  const fetchCategories = async () => {
    return await getCategories({
      token: token,
      filter: {},
    })
      .then((res) => (res.data.data ? res.data.data : []))
      .catch((e) => [])
      .finally(() => setIsFetchingCats(false));
  };
  useEffect(() => {
    if (token) {
      fetchCategories().then((data) => {
        setCategories(data);
      });
    }
  }, [token]);

  const handleSaveFavorite = async (webtool) => {
    if (loadingFavoriteIds.includes(webtool.id)) {
      return;
    } else {
      let filtredLoading = [...loadingFavoriteIds, webtool.id];
      setLoadingFavoriteIds(filtredLoading);
    }
    try {
      await saveFavorite({
        id: webtool.id,
        token: token,
        organizationId: navCommunity.id,
      });
      if (favoriteIds.includes(webtool.id)) {
        let filtredFavorites = favoriteIds.filter((fav) => fav !== webtool.id);
        setFavoriteIds(filtredFavorites);
      } else {
        let filtredFavorites = [...favoriteIds, webtool.id];
        setFavoriteIds(filtredFavorites);
      }
      let filtredLoadingFavorites = loadingFavoriteIds.filter(
        (fav) => fav !== webtool.id
      );
      setLoadingFavoriteIds(filtredLoadingFavorites);
      toast.success(_("saveSuccess"));
    } catch (e) {
      toast.error(_("savingError"), { autoClose: true });
      return null;
    }
  };

  const fetchFavoriteWebtools = async () => {
    // setIsFetchingRecomanded(true);
    return await getFavorites({
      token: token,
      community: navCommunity.id,
    })
      .then((res) => (res.data.data ? res.data.data : []))
      .catch((e) => []);
    // .finally(() => setIsFetchingRecomanded(false));
  };
  // end favorites webtools

  useEffect(() => {
    if (token && navCommunity) {
      fetchFavoriteWebtools().then((data) => {
        setFavoriteIds(data.map((item) => item.id));
      });
    }
  }, [token, navCommunity]);

  useEffect(() => {
    if (token && navCommunity) {
      if (navCommunity?.webtoolsPreferences?.source?.includes("MY_LIBRARY")) {
        getWebtools({
          token: token,
          community: navCommunity.id,
          limit: 200,
          workspace: "fiduciary",
        })
          .then((res) => {
            if (res.data.data && res.data.data.length > 0) {
              setOrgWebtools(res.data.data);
            }
          })
          .catch((e) => {
            setOrgWebtools([]);
          });
      }
      if (navCommunity?.webtoolsPreferences?.source?.includes("FFF_LIBRARY")) {
        getWebtools({
          token: token,
          community: 9,
          filter: { origin: true, csScope: ["PUBLIC", "ALL_COLLABORATORS"] },
          limit: 200,
          workspace: "fiduciary",
        })
          .then((res) => {
            if (res.data.data && res.data.data.length > 0) {
              setFffWebtools(res.data.data);
            }
          })
          .catch((e) => {
            setFffWebtools([]);
          });
      }

      if (navCommunity?.webtoolsPreferences?.source?.includes("COLLEAGUE")) {
        getWebtools({
          token: token,
          filter: {
            canBeShared: true,
            notOrgId: navCommunity.id,
            bannedIds: navCommunity?.webtoolsPreferences?.banned_ids ?? [],
            csScope: ["PUBLIC"],
          },
          limit: 200,
          workspace: "rss",
        })
          .then((res) => {
            if (res.data.data && res.data.data.length > 0) {
              setColleagueWebtools(res.data.data);
            }
          })
          .catch((e) => {
            setColleagueWebtools([]);
          });
      }
    }
  }, [token, navCommunity]);

  useEffect(() => {
    if (navCommunity) {
      let all = [];
      if (navCommunity.id === 9) {
        all = orgWebtools;
      } else {
        all = navCommunity.webtoolsPreferences?.source?.includes("MY_LIBRARY")
          ? orgWebtools
          : [];

        all = navCommunity.webtoolsPreferences?.source?.includes("FFF_LIBRARY")
          ? [...all, ...fffWebtools]
          : all;
        all = navCommunity.webtoolsPreferences?.source?.includes("COLLEAGUE")
          ? [...all, ...colleagueWebtools]
          : all;
      }

      const uniqWebtools = all.reduce((acc, cur) => {
        const exist = acc.find((w) => w.id === cur.id);
        if (!exist) {
          acc.push(cur);
        }
        return acc;
      }, []);
      setAllWebtools(uniqWebtools);
    }
  }, [navCommunity, orgWebtools, fffWebtools, colleagueWebtools]);

  return (
    <div className="page-content">
      {navCommunity?.webtoolsPreferences?.source?.includes("MY_LIBRARY") && (
        <Link to="/management-space#open-form">
          <div className="addButton">
            <PlusCircleOutlined style={{ fontSize: "27px" }} />
            <span className="add-text">{_("Add webtool")}</span>
          </div>
        </Link>
      )}
      <div className="align-center medium-11">
        {!token || !navCommunity ? (
          <div className="ripple_loading">
            <img src={"/img/Ripple-1s-150px.svg"} alt="" />
          </div>
        ) : (
          <>
            <div className="header">
              {_("allWebToolsOf")}{" "}
              <span>{navCommunity ? navCommunity.url : ""}</span>
            </div>
            <WebtoolFilter
              filter={filter}
              setFilter={setFilter}
              tooltipIsVisible={tooltipIsVisible}
              setVisibilityTooltip={setVisibilityTooltip}
              allowedActions={allowedActions}
              loadingFavoriteIds={loadingFavoriteIds}
              favoriteIds={favoriteIds}
              language={language}
              handleSaveFavorite={handleSaveFavorite}
              // setWebtoolEdited={setWebToolEdited}
              // setWebtoolDeleted={setWebToolDeleted}
              categories={categories}
              organizationWebtools={allWebtools}
              searchedWebtools={searchedWebtools}
              setSearchedWebtools={setSearchedWebtools}
            />
            {filter.categoryId.length === 0 &&
              filter.keyWord === "" &&
              filter.lng.length === 0 && (
                <>
                  <OrganizationWebtools
                    title={_("recommandedBy")}
                    subTitle={navCommunity?.name ?? ""}
                    description={_("home_fiduciary_desc")}
                    workspace="fiduciary"
                    logoUrl={navCommunity.avatarUrl}
                    logoWidth=""
                    communityId={navCommunity.id}
                    limit={9}
                    setVisibilityTooltip={setVisibilityTooltip}
                    loadingFavoriteIds={loadingFavoriteIds}
                    favoriteIds={favoriteIds}
                    language={language}
                    // setWebToolEdited={setWebToolEdited}
                    // setWebToolDeleted={setWebToolDeleted}
                    handleSaveFavorite={handleSaveFavorite}
                    isEnabled={navCommunity?.webtoolsPreferences?.source?.includes(
                      "MY_LIBRARY"
                    )}
                    user={user}
                    allowedActions={allowedActions}
                  />
                  {navCommunity.id !== 9 && (
                    <OrganizationWebtools
                      title={_("recommandedBy")}
                      subTitle="Forum For The Future"
                      description=""
                      workspace="fff"
                      communityId={9} // Forum for the future
                      filter={{
                        notSourceId: navCommunity.id,
                        csScope: ["PUBLIC", "ALL_COLLABORATORS"],
                      }}
                      limit={9}
                      logoUrl="/img/fff-logo.png"
                      logoWidth=""
                      setVisibilityTooltip={setVisibilityTooltip}
                      loadingFavoriteIds={loadingFavoriteIds}
                      favoriteIds={favoriteIds}
                      language={language}
                      // setWebToolEdited={setWebToolEdited}
                      // setWebToolDeleted={setWebToolDeleted}
                      handleSaveFavorite={handleSaveFavorite}
                      isEnabled={navCommunity?.webtoolsPreferences?.source?.includes(
                        "FFF_LIBRARY"
                      )}
                    />
                  )}
                  {navCommunity.id !== 9 && (
                    <OrganizationWebtools
                      title={_("recommandedBy")}
                      subTitle={_("ourColleagues")}
                      description=""
                      workspace="rss"
                      filter={{
                        canBeShared: true,
                        notOrgId: navCommunity.id,
                        bannedIds:
                          navCommunity?.webtoolsPreferences?.banned_ids ?? [],
                        csScope: ["PUBLIC"],
                      }}
                      logoUrl="/img/ua-logo.png"
                      logoWidth="160"
                      limit={9}
                      setVisibilityTooltip={setVisibilityTooltip}
                      loadingFavoriteIds={loadingFavoriteIds}
                      favoriteIds={favoriteIds}
                      language={language}
                      // setWebToolEdited={setWebToolEdited}
                      // setWebToolDeleted={setWebToolDeleted}
                      handleSaveFavorite={handleSaveFavorite}
                      isEnabled={navCommunity?.webtoolsPreferences?.source?.includes(
                        "COLLEAGUE"
                      )}
                    />
                  )}
                </>
              )}
          </>
        )}
      </div>
    </div>
  );
}

export default Home;
