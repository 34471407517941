import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  getTTPUser,
  getClientCredential,
  getSelectedOrganization,
} from "../api";
import {
  getAuthInfosFromCookie,
  authHasExpired,
  getCookie,
  logout,
  createAuthCookie,
} from "../utils";
import { APP_ENV } from "../config";
import { setLanguage } from "./index";

const initialState = {
  fetching: false,
  fetched: false,
  token: "",
  clientToken: "",
  expiresIn: null,
  createdAt: null,
  user: null,
  error: null,
  loggedAs: "GUEST",
  loggedAsPost: null,
  loggedIsAdmin: false,
  navCommunity: null,
};

export const fetchNavCommunity = createAsyncThunk(
  "auth/fetchNavCommunity",
  async (tmp, { getState, dispatch }) => {
    const { token, navCommunity, user } = getState().auth;
    if (!token || !user) {
      return false;
    }

    return getSelectedOrganization(token).then((resp) => {
      const help = typeof resp === "string" ? JSON.parse(resp) : { ...resp };
      if (
        help &&
        help.data &&
        help.data.organization &&
        (!navCommunity || navCommunity.id !== help.data.organization.id)
      ) {
        const { organization } = help.data;
        const chosed = user.communities.filter(
          (com) => organization.id === com.id
        );

        if (!chosed || chosed.length === 0) {
          dispatch(setAuthLoggedAs("CLIENT"));
        }

        dispatch(
          setNavCommunity(
            user && user.communities && chosed && chosed.length > 0
              ? chosed[0]
              : organization
          )
        );
      }
    });
  }
);

export const fetchAuthUser = createAsyncThunk(
  "auth/fetchAuthUser",
  async (user, { getState, dispatch }) => {
    const state = getState();

    const response = await getTTPUser({
      userId: user.id,
      token: state.auth.token,
    });

    return response.data[0];
  }
);

export const fetchClientToken = createAsyncThunk(
  "auth/fetchClientToken",
  async (user, { getState, dispatch }) => {
    const response = await getClientCredential();
    const helpResp =
      typeof response === "string" ? JSON.parse(response) : response;
    return helpResp.token.access_token;
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async (user, { getState, dispatch }) => {
    let authInfos = getAuthInfosFromCookie();
    if (!authInfos || authHasExpired(authInfos)) {
      logout();
    }

    const cookieLang = getCookie(`ttp_lang_${APP_ENV}`);
    if (cookieLang) {
      dispatch(setLanguage(cookieLang));
    } else {
      const lng = localStorage.getItem("lng") || "fr";
      dispatch(setLanguage(lng));
    }

    if (authInfos) {
      dispatch(setAuthToken(authInfos.token));
      dispatch(setExpiresIn(authInfos.expiresIn));
      dispatch(setTokenCreatedAt(authInfos.createdAt));
      dispatch(fetchAuthUser(authInfos));
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthTokenUser: (state, { payload }) => {
      createAuthCookie({
        token: payload.token,
        user: payload.data.user,
      });
      state.token = payload.token.access_token;
      state.expiresIn = payload.token.expiresIn;
      state.createdAt = payload.token.createdAt;
      state.user = payload.data.user;
      state.user.role = payload.data.role;
      if (payload.data.organization && payload.data.organization.length > 0) {
        state.user.communities = [payload.data.organization];
        state.navCommunity = payload.data.organization;
      }

      state.loggedAs =
        payload.data.role && payload.data.role.typeStatus === "FOLDER"
          ? "CLIENT"
          : "COLLABORATOR";
      state.loggedAsPost =
        payload.data.role && payload.data.role.type
          ? payload.data.role.type
          : null;
      if (payload.data.role) {
        if (
          ["LEGAL_REPRESENTATIVE", "MANAGER"].includes(
            payload.data.role.type
          ) &&
          payload.data.role.typeStatus !== "FOLDER"
        ) {
          state.loggedIsAdmin = true;
        }
      }
    },
    setAuth: (state, { payload }) => {
      state = { ...state, payload };
    },
    setAuthUser: (state, action) => {
      state.user = action.payload;
    },
    setAuthLoggedAs: (state, action) => {
      state.loggedAs = action.payload;
    },
    setAuthLoggedAsPost: (state, action) => {
      state.loggedAsPost = action.payload;
    },
    setAuthLoggedIsAdmin: (state, action) => {
      state.loggedIsAdmin = action.payload;
    },
    setExpiresIn: (state, action) => {
      state.expiresIn = action.payload;
    },
    setTokenCreatedAt: (state, action) => {
      state.createdAt = action.payload;
    },
    setAuthToken: (state, action) => {
      state.token = action.payload;
    },
    setClientToken: (state, action) => {
      state.clientToken = action.payload;
    },
    setNavCommunity: (state, action) => {
      state.navCommunity = action.payload;
    },
    setAuthFetched: (state, { payload }) => {
      state.fetched = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchClientToken.fulfilled, (state, action) => {
      state.clientToken = action.payload;
    });
    builder.addCase(fetchAuthUser.fulfilled, (state, { payload }) => {
      state.user = payload;
      // if (payload.communities && payload.communities.length > 0) {
      //   if (
      //     payload.role &&
      //     payload.role.organization.id === payload.communities[0].id &&
      //     payload.role.organization.owner
      //   ) {
      //     state.navCommunity = payload.communities.filter(
      //       (el) => el.id === payload.role.organization.owner.id
      //     )[0];
      //   } else {
      //     if (payload.communities.length === 1) {
      //       state.navCommunity = payload.communities[0];
      //     } else {
      //       const community = payload.communities.filter(
      //         (c) => c.officeType === "ITAA"
      //       );
      //       if (community && community.length > 0) {
      //         state.navCommunity = community[0];
      //       } else {
      //         state.navCommunity = payload.communities[0];
      //       }
      //     }
      //   }
      // }
      state.loggedAs =
        payload.role && payload.role.typeStatus === "FOLDER"
          ? "CLIENT"
          : "COLLABORATOR";
      state.loggedAsPost =
        payload.role && payload.role.type ? payload.role.type : null;
      if (payload.role) {
        if (
          ["LEGAL_REPRESENTATIVE", "MANAGER"].includes(payload.role.type) &&
          payload.role.typeStatus !== "FOLDER"
        ) {
          state.loggedIsAdmin = true;
        }
      }
      state.fetched = true;
    });
    builder.addCase(fetchAuthUser.rejected, (state, action) => {
      state.fetched = true;
    });
  },
});

export const {
  setAuth,
  setAuthUser,
  setAuthLoggedAs,
  setAuthLoggedAsPost,
  setAuthLoggedIsAdmin,
  setAuthToken,
  setClientToken,
  setExpiresIn,
  setTokenCreatedAt,
  setNavCommunity,
  setAuthTokenUser,
  setAuthFetched,
} = authSlice.actions;

export default authSlice.reducer;
