import React from "react";

export default ({ width = 16, height = 16, ...props }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.668 2.11458C12.5079 2.11458 12.3494 2.14611 12.2015 2.20737C12.0536 2.26863 11.9192 2.35842 11.8061 2.47161L2.93124 11.3464L2.2848 13.7167L4.6551 13.0703L13.5299 4.19546C13.6431 4.08227 13.7329 3.9479 13.7941 3.80001C13.8554 3.65212 13.8869 3.49361 13.8869 3.33353C13.8869 3.17346 13.8554 3.01495 13.7941 2.86706C13.7329 2.71917 13.6431 2.5848 13.5299 2.47161C13.4167 2.35842 13.2823 2.26863 13.1345 2.20737C12.9866 2.14611 12.8281 2.11458 12.668 2.11458ZM11.6913 0.975531C12.0009 0.847267 12.3328 0.78125 12.668 0.78125C13.0032 0.78125 13.335 0.847267 13.6447 0.975531C13.9544 1.1038 14.2357 1.2918 14.4727 1.5288C14.7097 1.7658 14.8977 2.04716 15.026 2.35682C15.1543 2.66648 15.2203 2.99837 15.2203 3.33353C15.2203 3.6687 15.1543 4.00059 15.026 4.31025C14.8977 4.61991 14.7097 4.90127 14.4727 5.13827L5.47272 14.1383C5.39069 14.2203 5.28866 14.2795 5.17673 14.31L1.51006 15.31C1.27925 15.373 1.03241 15.3074 0.863246 15.1383C0.69408 14.9691 0.628527 14.7223 0.691475 14.4915L1.69148 10.8248C1.722 10.7129 1.78121 10.6108 1.86325 10.5288L10.8632 1.5288C11.1002 1.2918 11.3816 1.1038 11.6913 0.975531Z"
        fill="#18A0FB"
      />
    </svg>
  );
};
