import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Header } from "tamtam-components";

import {
  APP_ENV,
  TTP_WEBTOOL_URL,
  TTP_EMAILING_URL,
  TTP_HOME_URL,
} from "../../config";
import { setLanguage, setNavCommunity } from "../../store";
import { logout, setCookie } from "../../utils";
import _ from "../../i18n";

function Menu() {
  const lng = useSelector((state) => state.params.language);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const changeLng = (language) => {
    dispatch(setLanguage(language));
  };

  const selectCommunity = (community) => {
    dispatch(setNavCommunity(community));
    let dtExpire = new Date();
    dtExpire.setTime(dtExpire.getTime() + 9900000 * 1000);
    setCookie(
      "ttp_community_" + APP_ENV,
      community.id,
      dtExpire,
      "/",
      "tamtam.pro"
    );
  };

  const handleLogout = (e) => {
    e.preventDefault();
    logout();
  };
  let settings = auth.navCommunity?.webtoolsPreferences?.source?.includes(
    "MY_LIBRARY"
  )
    ? [
        {
          label: _("managementSpace"),
          url: "/management-space",
        },
      ]
    : [];
  const rightIcons = {
    ebox: { activated: true, icon: "Ebox", url: `${TTP_EMAILING_URL}e_box` },
    notifs: { activated: true },
    faq: { activated: true },
    apps: {
      activated: true,
    },
    home: {
      activated: true,
      icon: "Portal",
    },
    profile: {
      activated: true,
      url: `${TTP_HOME_URL}/profile`,
      icon: "Profile",
    },
    search: { activated: false },
  };
  if (!auth.user || !auth.user.id) {
    return (
      <Header
        app={{
          appName: "Webtools",
          appLogoUrl: "https://s3.tamtam.pro/v2/apps/webtools.png",
          appUrl: `${TTP_WEBTOOL_URL}`,
          homeUrl: TTP_HOME_URL,
        }}
        auth={{ navCommunity: null, user: null }}
        lng={lng}
        env={APP_ENV}
        onLanguageChange={(lang) => changeLng(lang)}
        rightIcons={rightIcons}
        onSelectCommunity={(community) => selectCommunity(community)}
        settings={[]}
      />
    );
  }
  return (
    <Header
      auth={{
        navCommunity: auth.navCommunity,
        user: auth.user,
        token: auth.token,
      }}
      env={APP_ENV}
      lng={lng}
      onLanguageChange={(langue) => changeLng(langue)}
      rightIcons={rightIcons}
      app={{
        appName: "Webtools",
        appLogoUrl: "https://s3.tamtam.pro/v2/apps/webtools.png",
        appUrl: `${TTP_WEBTOOL_URL}`,
        homeUrl: TTP_HOME_URL,
      }}
      settings={auth.user?.type === "ADMIN" ? settings : []}
      onLogoutClick={(e) => handleLogout(e)}
      onSelectCommunity={(community) => selectCommunity(community)}
    />
  );
}

export default Menu;
