import axios from "axios";

import { TTP_API_URL } from "../config";

export const getCategories = ({ token, filter }) => {
  const requestUrl = `${TTP_API_URL}/media/category`;

  let language = filter.lng?.charAt(0).toUpperCase() + filter.lng?.slice(1);
  const filters = [{ property: "type", value: "WEBTOOL", operator: "like" }];

  if (filter?.search) {
    filters.push({
      property: `name${language}`,
      value: "%" + filter.search + "%",
      operator: "like",
    });
  }
  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filters),
    },
  });
};

export const saveCategory = ({
  token,
  id,
  organization,
  icon,
  color,
  languages,
  titleFr,
  titleNl,
  titleEn,
  urlNameFr = "",
  urlNameNl = "",
  urlNameEn = "",
  tags = "",
  creator,
}) => {
  const requestUrl = `${TTP_API_URL}/media/category`;
  let formData = new FormData();
  formData.append("access_token", token);
  formData.append("type", "WEBTOOL");
  if (id) {
    formData.append("id", id);
  }
  if (icon) {
    formData.append("icon", icon.id);
    formData.append("iconLabel", icon.label);
  }
  if (icon) {
    formData.append("colorCode", color);
  }
  if (titleFr) {
    formData.append("nameFr", titleFr);
  }

  if (titleNl) {
    formData.append("nameNl", titleNl);
  }

  if (titleEn) {
    formData.append("nameEn", titleEn);
  }

  if (urlNameFr) {
    formData.append("urlNameFr", urlNameFr);
  }

  if (urlNameNl) {
    formData.append("urlNameNl", urlNameNl);
  }

  if (urlNameEn) {
    formData.append("urlNameEn", urlNameEn);
  }

  if (languages && tags) {
    formData.append(
      "tags",
      languages.includes("FR")
        ? tags.nameFr
        : languages.includes("EN")
        ? tags.nameEn
        : languages.includes("NL")
        ? tags.nameNl
        : ""
    );
  }
  creator && formData.append("user", creator.id);
  organization && formData.append("organization", organization.id);

  return axios.post(requestUrl, formData);
};

export const deleteCategory = ({ token, categoryId }) => {
  const requestUrl = `${TTP_API_URL}/media/category/${categoryId}`;

  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("_method", "delete");

  return axios.post(requestUrl, formData);
};
