import React, { useEffect, useState } from "react";
import styles from "./AddWebtool.module.scss";
import { PlusCircleOutlined } from "@ant-design/icons";

import _ from "../../../i18n";
import classnames from "classnames";
import SideBar from "./SideBar";
import Chain from "./Chain";
import ChainSuggestion from "./ChainSuggestion";
import { useSelector } from "react-redux";
import SortableAuthorList from "./SortableAuthorList";
import AuthorSuggestion from "./AuthorSuggestion";
import { arrayMoveImmutable } from "array-move";
import {
  formattedTags,
  getAuthorAllHeadlines,
  getAuthorHeadlines,
  getTagName,
} from "utils";
import { fetchTTPUser } from "api/user";
import IconsModal from "./IconsModal";
import Switch from "components/Commun/Switch/Switch";
import Tabs from "components/Commun/Tabs";
import { APP_ENV } from "config";
import Category from "./Category";
import { SELECT_STYLES } from "config";
import SelectPage from "./SelectPage";
import SelectTheme from "./SelectTheme";
import Status from "./Status";
import Scope from "./Scope";
import Button from "components/Commun/Button";
import LoaderIcon from "components/Icons/LoaderIcon";
import { LANG_INDEX } from "config";
import { LANG_TRANS } from "config";
import { saveWebtool } from "api";
import { toast } from "react-toastify";
import { TTP_API_URL } from "config";
import { Tag } from "tamtam-components";

const AddWebtool = ({
  hideAddButton,
  setWebtoolEdited,
  webtoolEdited,
  updateWebTools,
  isOpen,
  setIsOpen,
}) => {
  const auth = useSelector((state) => state.auth);
  const { user, token, navCommunity } = auth;
  const language = useSelector((state) => state.params.language);

  // const [chain, setChain] = useState(null);

  const [currentWebtool, setCurrentWebtool] = useState(null);
  const [uploadClicked, setUploadIsClicked] = useState(false);
  const [switchLanguages, setSwitchLanguages] = useState(LANG_INDEX);
  const [isSaving, setIsSaving] = useState(false);

  // const [authors, setAuthors] = useState([]);
  // const [selectedIcon, setSelectedIcon] = useState(null);
  // const [category, setCategory] = useState(null);
  const [theme, setTheme] = useState(null);
  // const [pages, setPages] = useState([]);
  const [themeObject, setThemeObject] = useState(null);
  // const [tags, setTags] = useState([]);
  // const [status, setStatus] = useState("DRAFT");
  const [checkedItems, setCheckedItems] = useState(["PUBLIC"]);
  // const [groups, setGroups] = useState([]);
  // const [specCollaborators, setSpecCollaborators] = useState([]);
  // const [specClients, setSpecClients] = useState([]);
  // const [specContacts, setSpecContacts] = useState([]);
  // const [isProposed, setIsProposed] = useState(false);
  // const [isSharedWithColleague, setIsSharedWithColleague] = useState(false);
  // const [titles, setTitles] = useState({ fr: "", en: "", nl: "" });
  // const [urls, setUrls] = useState({ fr: "", en: "", nl: "" });
  // const [descriptions, setDescriptions] = useState({ fr: "", en: "", nl: "" });

  const titleAttr = `title${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;
  const nameAttr = `name${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;
  const initForm = {
    titles: { fr: "", en: "", nl: "" },
    urls: { fr: "", en: "", nl: "" },
    descriptions: { fr: "", en: "", nl: "" },
    canBeShared: true,
    fffLibrary: false,
    status: "PUBLISHED",
    specContacts: [],
    specClients: [],
    specCollaborators: [],
    groups: [],
    tags: [],
    pages: [],
    authors: [],
    theme: null,
    category: null,
    icon: null,
    chain: null,
    // csScope: "PUBLIC",
  };
  const [form, setForm] = useState(initForm);

  const handleChange = (v, name) => {
    setForm({
      ...form,
      [name]: v,
    });
  };

  const handleCheckChange = (e, name) => {
    setForm({
      ...form,
      [name]: e.target.checked,
    });
  };

  // const handleThemeChange = (v) => {
  //   console.log(v);
  //   setForm({
  //     ...form,
  //     theme: v,
  //   });
  // };

  const handleInputChange = (e, name, lng) => {
    let help = {};
    let newVal = {};
    newVal[lng] = e.target ? e.target.value : e;
    help[name] = { ...form[name], ...newVal };
    setForm({ ...form, ...help });
  };

  useEffect(() => {
    setIsOpen(webtoolEdited ? true : false);
    if (webtoolEdited) {
      let sLang = [];
      if (webtoolEdited.titleFr.length > 0) sLang.push("fr");
      if (webtoolEdited.titleEn.length > 0) sLang.push("en");
      if (webtoolEdited.titleNl.length > 0) sLang.push("nl");
      setSwitchLanguages(sLang);
      setCheckedItems(webtoolEdited.csScope.split(","));
      let avatar = "";
      let avatarUrl = "";
      let media = webtoolEdited?.chain?.mediaChain?.filter(
        (item) => item.language === language
      );
      if (media && media.length === 1) {
        avatar = media[0].avatar;
        avatarUrl = media[0].avatarUrl;
      }
      const categoryName =
        webtoolEdited.category[nameAttr] ||
        webtoolEdited.category["nameFr"] ||
        webtoolEdited.category["nameEn"] ||
        webtoolEdited.category["nameNl"];

      const themeTitle = !webtoolEdited.theme
        ? ""
        : webtoolEdited.theme[titleAttr] ||
          webtoolEdited.theme["titleFr"] ||
          webtoolEdited.theme["titleEn"] ||
          webtoolEdited.theme["titleNl"];

      const wtTags = webtoolEdited.tags
        ? webtoolEdited.tags?.map((tag) => {
            let label = getTagName(tag, language);
            let tmp = {
              label,
              name: tag[nameAttr],
              value: tag.id,
              tag: {
                id: tag.id,
                nameEn: tag.nameEn,
                nameFr: tag.nameFr,
                nameNl: tag.nameNl,
              },
            };
            return tmp;
          })
        : [];

      const wtPages = webtoolEdited.pages
        ? webtoolEdited.pages.map((page) => {
            const pageTitle =
              page[titleAttr] ||
              page["titleFr"] ||
              page["titleEn"] ||
              page["titleNl"];
            return {
              id: page.id,
              title: pageTitle,
            };
          })
        : [];

      if (webtoolEdited.theme) {
        setTheme(
          webtoolEdited.theme
            ? { ...webtoolEdited.theme, title: themeTitle }
            : null
        );
        setThemeObject(webtoolEdited.theme);
      }
      setForm({
        ...form,
        tags: wtTags ?? [],
        category: webtoolEdited.category
          ? { ...webtoolEdited.category, name: categoryName }
          : null,

        pages: wtPages ?? [],
        csScope: webtoolEdited.csScope,
        specCollaborators: webtoolEdited.specCollaborators ?? [],
        specClients: webtoolEdited.specClients ?? [],
        specContacts: webtoolEdited.specContacts ?? [],
        groups: webtoolEdited.groups ?? [],
        icon: webtoolEdited.icon ?? null,
        chain:
          webtoolEdited.chain && webtoolEdited.chain.id
            ? { ...webtoolEdited.chain, avatar, avatarUrl }
            : null,
        status: webtoolEdited.status,
        canBeShared: webtoolEdited.canBeShared,
        fffLibrary: webtoolEdited.fffLibrary === "PROPOSED",
        titles: {
          fr: webtoolEdited.titleFr ?? "",
          en: webtoolEdited.titleEn ?? "",
          nl: webtoolEdited.titleNl ?? "",
        },
        urls: {
          fr: webtoolEdited.urlNameFr ?? "",
          en: webtoolEdited.urlNameEn ?? "",
          nl: webtoolEdited.urlNameNl ?? "",
        },
        authors: webtoolEdited.authors ?? [],

        descriptions: {
          fr: webtoolEdited.descriptionFr ?? "",
          en: webtoolEdited.descriptionEn ?? "",
          nl: webtoolEdited.descriptionNl ?? "",
        },
      });
      // setDescriptionFr(webtoolEdited.descriptionFr);
      // setDescriptionEn(webtoolEdited.descriptionEn);
      // setDescriptionNl(webtoolEdited.descriptionNl);

      // setIsClicked(true);
      // webtoolEdited.descriptionFr &&
      //   setEditorStateFr(
      //     EditorState.createWithContent(
      //       convertFromRaw(JSON.parse(webtoolEdited.descriptionFr))
      //     )
      //   );
      // webtoolEdited.descriptionEn &&
      //   setEditorStateEn(
      //     EditorState.createWithContent(
      //       convertFromRaw(JSON.parse(webtoolEdited.descriptionEn))
      //     )
      //   );
      // webtoolEdited.descriptionNl &&
      //   setEditorStateNl(
      //     EditorState.createWithContent(
      //       convertFromRaw(JSON.parse(webtoolEdited.descriptionNl))
      //     )
      //   );
      // let lngs = ["ALL", "FR", "EN", "NL"];
      // if (webtoolEdited.titleFr?.length === 0) {
      //   lngs = lngs.filter((l) => "FR" !== l);
      //   setSwitchLanguages(lngs);
      // }
      // if (webtoolEdited.titleNl?.length === 0) {
      //   lngs = lngs.filter((l) => "NL" !== l);
      //   setSwitchLanguages(lngs);
      // }
      // if (webtoolEdited.titleEn?.length === 0) {
      //   lngs = lngs.filter((l) => "EN" !== l);
      //   setSwitchLanguages(lngs);
      // }
      // if (lngs.length < 4) {
      //   lngs = lngs.filter((l) => "ALL" !== l);
      //   setSwitchLanguages(lngs);
      // }

      // setInputTitleStateFr(webtoolEdited.titleFr);
      // setInputTitleStateEn(webtoolEdited.titleEn);
      // setInputTitleStateNl(webtoolEdited.titleNl);
      // setInputUrlStateFr(webtoolEdited.urlNameFr);
      // setInputUrlStateEn(webtoolEdited.urlNameEn);
      // setInputUrlStateNl(webtoolEdited.urlNameNl);
      // onChangeSelectCategory(webtoolEdited.category?.id);
      // setTagsState(webtoolEdited.tags);
      // selectIcon(webtoolEdited.icon);
      // setCheckedItems(webtoolEdited.csScope.split(","));
      // setSpecCollaborators(webtoolEdited.specCollaborators);
      // setSpecClients(webtoolEdited.specClients);
      // setSpecContacts(webtoolEdited.specContacts);
      // setSelectedGroups(webtoolEdited.groups);
      // if (webtoolEdited.theme) {
      //   setPages(webtoolEdited.theme.pages);
      //   setSelectTheme(webtoolEdited.theme.id);
      //   setSelectPages(
      //     webtoolEdited.pages ? webtoolEdited.pages.map((p) => p.id) : []
      //   );
      // } else {
      //   setSelectTheme("");
      //   setSelectPages([]);
      // }
    }
  }, [webtoolEdited]);

  useEffect(() => {
    if (
      !webtoolEdited &&
      user &&
      form.authors.length === 0 &&
      navCommunity &&
      isOpen
    ) {
      handleEmptyAuthors();
    }
  }, [navCommunity, isOpen]);

  useEffect(() => {
    if (!checkedItems.includes("PUBLIC")) {
      setForm({ ...form, canBeShared: false, fffLibrary: false });
    }
  }, [checkedItems]);

  const handleEmptyAuthors = async () => {
    const authorTitle =
      user.defaultSignature && user.defaultSignature.title
        ? user.defaultSignature.title
        : user.firstName + " " + user.lastName;
    let head = getAuthorHeadlines(user, language);

    if (navCommunity) {
      const res = await fetchTTPUser({
        userId: user.id,
        token: token,
      });
      const tmpUser = res.data ? res.data[0] : null;

      const headlineAttr = `headline${
        language.charAt(0).toUpperCase() + language.slice(1)
      }`;
      if (tmpUser) {
        const blogRole = tmpUser.blogRole
          ? tmpUser.blogRole.filter(
              (item) => item.organizationId === navCommunity?.value
            )[0]
          : null;
        if (blogRole && blogRole[headlineAttr]) {
          head = blogRole[headlineAttr].title
            ? blogRole[headlineAttr].title
            : "";
        }
      }
    }
    let author = {
      id: user.id,
      signature: {
        title: authorTitle,
        head: head,
      },
      avatar: user.avatarUrl || null,
      avatarUrl: user.avatarUrl || null,
      enableAvatar: true,
      priority: 0,
      firstName: user.firstName,
      lastName: user.lastName,
      headlines: getAuthorAllHeadlines(user),
      isAuthor: true,
    };
    setForm({ ...form, authors: [author] });
  };

  const onSortAuthorsEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) {
      return;
    }

    let oldPriority = form.authors[oldIndex].priority;
    let newPriority = form.authors[newIndex].priority;

    let newAuthorsOrder = arrayMoveImmutable(form.authors, oldIndex, newIndex);
    newAuthorsOrder[newIndex] = {
      ...newAuthorsOrder[newIndex],
      priority: newPriority,
    };
    newAuthorsOrder[oldIndex] = {
      ...newAuthorsOrder[oldIndex],
      priority: oldPriority,
    };

    setForm({ ...form, authors: newAuthorsOrder });
  };

  // const handleDeleteAuthor = (author) => {
  //   let index = -1;
  //   for (let i = 0; i < form.authors.length; i++) {
  //     if (form.authors[i].id === author.id) {
  //       index = i;
  //       break;
  //     }
  //   }

  //   let newAuthors = form.authors.concat(); // For immutability
  //   // author.status = "DELETED";
  //   let newAuthor = Object.assign({}, author, { status: "DELETED" });

  //   if (index !== -1) {
  //     newAuthors[index] = newAuthor;
  //   }

  //   setForm({ ...form, authors: newAuthors });
  // };

  const handleDeleteAuthor = (author) => {
    const newAuthors = form.authors.filter((a) => a.id !== author.id);
    setForm({ ...form, authors: newAuthors });
  };

  const handleChangeAuthor = (newAuthor) => {
    let newAuthors = [...form.authors];

    let index = -1;
    for (let i = 0; i < newAuthors.length; i++) {
      if (
        newAuthors[i].id === newAuthor.id &&
        newAuthors[i].isAuthor === newAuthor.isAuthor
      ) {
        index = i;
        break;
      }
    }

    if (index === -1) {
      newAuthors.push(newAuthor);
    } else {
      newAuthors[index] = newAuthor;
    }
    setForm({ ...form, authors: newAuthors });
  };

  const onChangeLanguage = (language, activate) => {
    console.log(language, activate);
    if (activate === true) {
      setSwitchLanguages(
        LANG_INDEX.filter((l) => switchLanguages.includes(l) || l === language)
      );
    } else {
      if (switchLanguages.length > 1) {
        setSwitchLanguages(switchLanguages.filter((l) => l !== language));
      }
    }
  };

  const renderLanguages2 = () => {
    return (
      <div className={styles.lngSwitches}>
        {LANG_INDEX.map((language) => {
          return (
            <div key={`lng-${language}`} className={styles.switch}>
              <span>{language}</span>
              <Switch
                name={language}
                checked={switchLanguages.includes(language)}
                onChange={onChangeLanguage}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const renderLanguages = () => {
    return (
      <div className="w-full">
        <label className={styles.configLabel}>Languages</label>
        <ul className="switches">
          {LANG_INDEX.map((language) => {
            return (
              <li key={`lng-${language}`}>
                <div className="flex items-center justify-between">
                  <span>{language}</span>
                  <Switch
                    name={language}
                    checked={switchLanguages.includes(language)}
                    onChange={(e) =>
                      onChangeLanguage(language, e.target.checked)
                    }
                  />
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const enableSaveWebTool = () => {
    let titlesValide = true;
    let urlsValide = true;
    let descriptionsValide = true;

    switchLanguages.forEach((lng) => {
      if (titlesValide) {
        titlesValide = form.titles[lng].length > 0;
      }
      if (urlsValide) {
        urlsValide = form.urls[lng].length > 0;
      }
      if (descriptionsValide) {
        descriptionsValide = form.descriptions[lng].length > 0;
      }
      // if (descriptionsValide && lng === "fr") {
      //   descriptionsValide = descriptionFr.length > 0;
      // }
      // if (descriptionsValide && lng === "en") {
      //   descriptionsValide = descriptionEn.length > 0;
      // }
      // if (descriptionsValide && lng === "nl") {
      //   descriptionsValide = descriptionNl.length > 0;
      // }
    });
    return (
      titlesValide &&
      urlsValide &&
      descriptionsValide &&
      form.icon &&
      form.category &&
      form.pages.length > 0
    );
  };

  const validate = () => {
    let errors = [];
    let titlesValide = true;
    let urlsValide = true;
    let descriptionsValide = true;

    switchLanguages.forEach((lng) => {
      if (titlesValide && form.titles[lng].length === 0) {
        errors.push(_("title_required"));
        titlesValide = false;
      }
      if (urlsValide && form.urls[lng].length === 0) {
        errors.push(_("url_required"));
        urlsValide = false;
      }
      if (descriptionsValide && form.descriptions[lng].length === 0) {
        errors.push(_("description_required"));
        descriptionsValide = false;
      }
    });

    if (!form.icon) {
      errors.push(_("icon_required"));
    }

    if (!form.category) {
      errors.push(_("invalid_category"));
    }

    if (!form.pages || form.pages.length < 1) {
      errors.push(_("invalid_page"));
    }

    return errors;
  };
  const validateForm = () => {
    let errors = validate();
    if (errors && errors.length > 0) {
      let ErrorsContainer = ({ errors }) => (
        <div>
          <span>{_("errors") + " :"}</span>
          <ul>
            {errors.map((e, i) => (
              <li key={i}>{e}</li>
            ))}
          </ul>
        </div>
      );
      toast.error(<ErrorsContainer errors={errors} />, {
        autoClose: true,
      });
      return false;
    }
    return true;
  };

  const closeModal = () => {
    setForm(initForm);
    setWebtoolEdited(null);
    setIsOpen(false);
  };

  const handleSave = () => {
    // let category = categories.filter(function (c) {
    //   return c.id === selectCatgory;
    // });
    // let descriptionFr =
    //   editorStateFr &&
    //   JSON.stringify(convertToRaw(editorStateFr.getCurrentContent()));
    // let descriptionEn =
    //   editorStateEn &&
    //   JSON.stringify(convertToRaw(editorStateEn.getCurrentContent()));
    // let descriptionNl =
    //   editorStateNl &&
    //   JSON.stringify(convertToRaw(editorStateNl.getCurrentContent()));
    // console.log(form.pages);

    if (!validateForm()) {
      return;
    }

    let itemResult = {
      id: webtoolEdited ? webtoolEdited.id : null,
      token: auth.token,
      lng: language,
      organization: auth.navCommunity,
      ...form,
      theme,
      tags: formattedTags(form.tags, language),
      descriptionFr: form.descriptions?.fr ?? "",
      descriptionEn: form.descriptions?.en ?? "",
      descriptionNl: form.descriptions?.nl ?? "",

      titleFr: form.titles?.fr ?? "",
      titleEn: form.titles?.en ?? "",
      titleNl: form.titles?.nl ?? "",
      urlFr: form.urls?.fr ?? "",
      urlEn: form.urls?.en ?? "",
      urlNl: form.urls?.nl ?? "",
      creator: auth.user,
      languages: switchLanguages.map((l) => l.toUpperCase()),
      csScope: checkedItems,
      specCollaborators: form.specCollaborators.map((el) => {
        return { id: el.id, firstName: el.firstName, lastName: el.lastName };
      }),
      specClients: form.specClients.map((el) => {
        return {
          id: el.id,
          name: el.name,
        };
      }),
      specContacts: form.specContacts.map((el) => {
        return { id: el.id, firstName: el.firstName, lastName: el.lastName };
      }),
      groups: form.groups.map((el) => {
        return { id: el.id, name: el.name };
      }),
    };
    // console.log(itemResult.specClients);
    setIsSaving(true);
    saveWebtool(itemResult)
      .then((response) => {
        updateWebTools();
        webtoolEdited
          ? toast.success(_("webToolUpdated"))
          : toast.success(_("webToolSaved"));
        closeModal();
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <div>
      {hideAddButton ? null : (
        <div className={styles.addButton} onClick={() => setIsOpen(true)}>
          <PlusCircleOutlined style={{ fontSize: "27px" }} />
          <span
            style={{
              marginRight: "1rem",
              textAlign: "center",
              width: "100%",
              textTransform: "uppercase",
            }}
          >
            {_("Add webtool")}
          </span>
        </div>
      )}
      <SideBar title="ajouter un webtool" isOpen={isOpen} onClose={closeModal}>
        <div className={styles.leftSide}>
          <p>chaine</p>
          <div className={styles.chainContainer}>
            {form.chain ? (
              <Chain
                language={language}
                author={form.chain}
                onDelete={() => setForm({ ...form, chain: null })}
              />
            ) : (
              <ChainSuggestion
                selectedCommunity={auth.navCommunity}
                selectedLanguage={language}
                chain={form.chain}
                onChainChange={(v) => handleChange(v, "chain")}
              />
            )}
          </div>
          <p className={styles.authorTitle}>authors</p>
          <SortableAuthorList
            language={language}
            auth={auth}
            onChangeAuthor={(newAuthor) => handleChangeAuthor(newAuthor)}
            onDeleteAuthor={(author) => handleDeleteAuthor(author)}
            onSortEnd={onSortAuthorsEnd}
            helperClass={styles.sortableHelper}
            lockAxis="y"
            pressDelay={200}
            authors={form.authors}
          />

          <div className={styles.addAuthor}>
            <AuthorSuggestion
              authors={form.authors}
              onAuthorChange={handleChangeAuthor}
            />
          </div>
        </div>
        <div>
          <div className="flex  items-center">
            {renderLanguages()}
            <div className={styles.wrapIcon}>
              <div
                className={classnames(styles.select_icon, styles.webtool)}
                onClick={() => setUploadIsClicked(true)}
              >
                <div className={styles.icon}>
                  {form.icon ? (
                    <span
                      className={styles.icon_selected}
                      title={form.icon[titleAttr] || form.icon.label}
                      // style={{
                      //   color: `rgba(${color.rgb?.r}, ${color.rgb?.g}, ${color.rgb?.b}, ${color.rgb?.a})`,
                      // }}
                    >
                      <i className={`icon webtool-${form.icon.label}`} />
                      <span>
                        {(form.icon[titleAttr]?.length < 17
                          ? form.icon[titleAttr]
                          : form.icon[titleAttr]?.substring(0, 14) + "...") ||
                          (form.icon.label?.length < 16
                            ? form.icon.label
                            : form.icon.label?.substring(0, 13) + "...")}
                      </span>
                    </span>
                  ) : (
                    <span>
                      {_("select")} {_("icon")} *
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Tabs theme="wtheme">
            {switchLanguages.map((lng) => (
              <div
                key={`langtab${lng}`}
                label={LANG_TRANS[lng]}
                icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/widget/${APP_ENV}/img/flags/${lng.toUpperCase()}.jpg`}
              >
                <div className={styles.langTab}>
                  <div className={styles.formRow}>
                    <label className={styles.configLabel}>Title {lng}</label>
                    <input
                      className={styles.formInput}
                      value={form.titles[lng]}
                      onChange={(e) => handleInputChange(e, "titles", lng)}
                    />
                  </div>

                  <div className={styles.formRow}>
                    <label className={styles.configLabel}>Url {lng}</label>
                    <input
                      className={styles.formInput}
                      value={form.urls[lng]}
                      onChange={(e) => handleInputChange(e, "urls", lng)}
                    />
                  </div>

                  <label className={styles.configLabel}>
                    {_("description")} {lng}
                  </label>
                  <textarea
                    className={styles.formTextarea}
                    value={form.descriptions[lng]}
                    onChange={(e) => handleInputChange(e, "descriptions", lng)}
                  ></textarea>
                </div>
              </div>
            ))}
          </Tabs>
          <div className={styles.configRow}>
            <div className={styles.configColumn}>
              <label className={styles.configLabel}>{_("category")}</label>
              <Category
                setCategory={(v) => handleChange(v, "category")}
                category={form.category}
                selectStyles={SELECT_STYLES}
                language={language}
              />
            </div>
            <div className={styles.configColumn}>
              <label className={styles.configLabel}>{_("tags")}</label>
              <Tag
                token={token}
                lng={language}
                tags={form.tags}
                allowCreateTags={true}
                limitSuperTag={2}
                appEnv={APP_ENV}
                onChange={(v) => handleChange(v, "tags")}
                apiUrl={TTP_API_URL}
                styles={SELECT_STYLES}
              />
            </div>
          </div>
          <div className={styles.configRow}>
            <div className={styles.configColumn}>
              <label className={styles.configLabel}>{_("theme")}</label>
              <SelectTheme
                theme={theme}
                setTheme={setTheme}
                setPages={(v) => handleChange(v, "pages")}
                setThemeObject={setThemeObject}
              />
            </div>

            <div className={styles.configColumn}>
              <label className={styles.configLabel}>{_("page")}</label>
              <SelectPage
                themeObject={themeObject}
                pages={form.pages}
                setPages={(v) => handleChange(v, "pages")}
              />
            </div>
          </div>
          <div className={styles.configRow}>
            <div className={styles.configColumn}>
              <label className={styles.configLabel}>{_("scope")}</label>
              <Scope
                // scope={form.csScope}
                // // setScope={(v) => setForm({...form, })}

                groups={form.groups}
                checkedItems={checkedItems}
                setCheckedItems={setCheckedItems}
                specClients={form.specClients}
                specContacts={form.specContacts}
                specCollaborators={form.specCollaborators}
                setGroups={(v) => handleChange(v, "groups")}
                setSpecClients={(v) => handleChange(v, "specClients")}
                setSpecContacts={(v) => handleChange(v, "specContacts")}
                setSpecCollaborators={(v) =>
                  handleChange(v, "specCollaborators")
                }
              />
            </div>
            <div className={styles.configColumn}>
              {navCommunity?.webtoolsPreferences?.source?.includes(
                "COLLEAGUE"
              ) &&
                navCommunity.id !== 9 && (
                  <div className={styles.singleSwitch}>
                    <label className={styles.configLabel}>
                      {_("Partager avec confrère")}
                    </label>
                    <Switch
                      disabled={!checkedItems.includes("PUBLIC")}
                      checked={form.canBeShared}
                      onChange={(v) => handleCheckChange(v, "canBeShared")}
                    />
                  </div>
                )}
              {navCommunity?.webtoolsPreferences?.source?.includes(
                "FFF_LIBRARY"
              ) &&
                navCommunity.id !== 9 && (
                  <div className={styles.singleSwitch}>
                    <label className={styles.configLabel}>
                      {_("Proposer à FFF")}
                    </label>
                    <Switch
                      disabled={!checkedItems.includes("PUBLIC")}
                      checked={form.fffLibrary}
                      onChange={(v) => handleCheckChange(v, "fffLibrary")}
                    />
                  </div>
                )}

              <label className={styles.configLabel}>{_("status")}</label>
              <Status
                status={form.status}
                setStatus={(v) => handleChange(v, "status")}
              />
            </div>
          </div>
          <div className={styles.actions}>
            <div className={styles.cancel_butt} onClick={closeModal}>
              {_("cancel")}
            </div>
            <Button
              className={classnames(styles.save_butt)}
              // disabled={!enableSaveWebTool()}
              onClick={handleSave}
            >
              {isSaving ? (
                <LoaderIcon />
              ) : webtoolEdited ? (
                _("update")
              ) : (
                _("save")
              )}
            </Button>
          </div>
        </div>
      </SideBar>
      <IconsModal
        uploadClicked={uploadClicked}
        setUploadIsClicked={setUploadIsClicked}
        setSelectedIcon={(icon) => setForm({ ...form, icon })}
      />
    </div>
  );
};

export default AddWebtool;
