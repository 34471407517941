import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";

import Layout from "./components/Layout";
import Home from "./components/Home/Home";
import Settings from "./components/Settings";
import NotFoundV2 from "./components/Commun/NotFound/NotFoundV2";
import ManagementSpace from "./components/ManagementSpace";
import LibraryPage from "./components/Library/LibraryPage";
import MyAdditions from "./components/MySpaces/MyAdditions";
import MyFavorites from "./components/MySpaces/MyFavorites";

import "./App.scss";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="settings" element={<Settings />} />
            <Route path="management-space" element={<ManagementSpace />} />
            <Route path="/my-additions" element={<MyAdditions />} />
            <Route path="/my-favorites" element={<MyFavorites />} />
            <Route path="/library" element={<LibraryPage />} />
            <Route
              path="*"
              element={<NotFoundV2 errorPageType={"ERROR_404"} />}
            />
          </Route>
        </Routes>
        <ToastContainer autoClose={3000} />
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export default App;
