import React, { useEffect } from "react";

import { getUserNameForAvatar, addLandaSize } from "utils";
import _ from "i18n";

import styles from "./Author.module.scss";
import IconClose from "components/Icons/IconClose";
import IconEye from "components/Icons/IconEye";

const Author = (props) => {
  const { onChange, onDelete, removable, author, authorsCount } = props;

  const { language } = props;

  useEffect(() => {
    const prevLanguage = language;
    if (prevLanguage !== language) {
      const { signature } = author;
      if (author && signature) {
        const { title, head } = signature;
        const newHead = getAuthorHeadline(author, language, head);

        onChange({ ...author, signature: { head: newHead, title } });
      }
    }
  }, [author, language, onChange]);

  const handleChangeTitle = (e) => {
    const { signature } = author;
    const { head } = signature;

    onChange({
      ...author,
      signature: { title: e.target.value, head },
    });
  };

  const handleChangeHead = (e) => {
    const { signature } = author;
    const { title } = signature;
    onChange({
      ...author,
      signature: { head: e.target.value, title },
    });
  };

  const toggleEnableAvatar = (event) => {
    event.stopPropagation();
    const { enableAvatar, id } = author;
    if (id === 8650) {
      let next = true;
      if (enableAvatar === true) {
        next = false;
      } else if (enableAvatar === false) {
        if (authorsCount > 1) {
          next = "D";
        } else {
          next = true;
        }
      } else if (enableAvatar === "D") {
        next = true;
      }
      onChange({ ...author, enableAvatar: next });
    } else {
      onChange({ ...author, enableAvatar: !enableAvatar });
    }
  };

  const handleDeleteAuthor = (e) => {
    e.stopPropagation();
    onDelete(author);
  };

  const renderAvatar = () => {
    const { author } = props;

    const { enableAvatar, firstName, lastName, isAuthor, avatarUrl } = author;

    if (!avatarUrl) {
      const iconEyeClass =
        enableAvatar === "D"
          ? styles.iconEye +
            " " +
            styles.iconEyeDeleted +
            " " +
            styles.disabledAvatar
          : enableAvatar === false
          ? styles.iconEye + " " + styles.disabledAvatar
          : styles.iconEye;
      return (
        <div
          className={`${styles.sidebarAvatar} ${styles.emptyAvatar}`}
          style={{ backgroundImage: `url("/img/user-avatar.svg")` }}
        >
          {/* <div
            className={styles.avatarOverlay}
            style={
              enableAvatar === true && enableAvatar !== "D"
                ? { display: "none" }
                : { display: "block" }
            }
          ></div> */}
          {/* {isAuthor && ( */}
          <>
            <span>{getUserNameForAvatar(firstName, lastName)}</span>
            <span
              className={iconEyeClass}
              onClick={toggleEnableAvatar}
              title={enableAvatar ? "Disable" : "Enable"}
            >
              <IconEye size="16" />
            </span>
          </>
          {/* )} */}
        </div>
      );
    }

    const iconEyeClass =
      enableAvatar === "D"
        ? styles.iconEye + " " + styles.disabledAvatarDeleted
        : enableAvatar === false
        ? styles.iconEye + " " + styles.disabledAvatar
        : styles.iconEye;
    return (
      <div
        className={styles.sidebarAvatar}
        style={{ backgroundImage: `url(${addLandaSize(avatarUrl, 240)})` }}
      >
        <div
          className={styles.avatarOverlay}
          style={
            enableAvatar === true && enableAvatar !== "D"
              ? { display: "none" }
              : { display: "block" }
          }
        ></div>
        {/* {isAuthor && ( */}
        <span
          className={iconEyeClass}
          onClick={toggleEnableAvatar}
          title={enableAvatar ? "Disable" : "Enable"}
        >
          <IconEye size="16" />
        </span>
        {/* )} */}
      </div>
    );
  };

  const getAuthorHeadline = (author, language, currentHead) => {
    const { headlines } = author;

    if (headlines && headlines[language]) {
      return headlines[language];
    } else {
      return currentHead;
    }
  };

  const { enableAvatar, isAuthor, isAvatar, signature } = author;

  let title = "";
  let head = "";

  // if (isAuthor) {
  if (signature) {
    title = signature.title;
    head = signature.head;
  }
  // } else {
  //   title = author.company;
  //   head = author.headline;
  // }

  return (
    <div className={styles.sidebarAuthor}>
      {removable && (
        <div className={styles.authorRemove} onClick={handleDeleteAuthor}>
          <IconClose />
        </div>
      )}
      {renderAvatar()}
      <div className={`${styles.sidebarUsername} ${styles.editableInput}`}>
        <input
          type="text"
          style={!enableAvatar ? { opacity: 0.6 } : {}}
          readOnly={true}
          value={title}
          onChange={handleChangeTitle}
          placeholder={_("author_title")}
        />
      </div>
      {/* {isAvatar ? "avatar" : "not avatar"} */}
      {/* {(isAuthor || (isAvatar && head)) && ( */}

      <div className={`${styles.sidebarPost} ${styles.editableInput}`}>
        <input
          type="text"
          style={!enableAvatar ? { opacity: 0.6 } : {}}
          readOnly={!enableAvatar}
          value={head}
          onChange={handleChangeHead}
          placeholder={_("author_headline")}
        />
      </div>
    </div>
  );
};

export default Author;
