import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import MultiSwitchComponent from "./MultiSwitchComponent";

export default class MultiSwitchContainer extends PureComponent {
  state = {
    selectedOption: this.props.selectedValue || this.props.vals[0],
  };

  static propTypes = {
    vals: PropTypes.array,
    labels: PropTypes.array,
    name: PropTypes.string,
    onChange: PropTypes.func,
  };
  render() {
    const { selectedOption } = this.state;

    return (
      <MultiSwitchComponent
        selectedOption={this.props.selectedValue}
        {...this.props}
        onChange={this.handleChange}
      />
    );
  }

  handleChange = (e) => {
    const val = e.target.value;
    const { afterChange } = this.props;
    afterChange(val);
    this.setState({ selectedOption: val });
  };
}
