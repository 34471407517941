import React from "react";
import MultiSwitchContainer from "components/Commun/Switch/MultiSwitch/MultiSwitchContainer";
import _ from "i18n";

const Status = ({ status, setStatus }) => {
  let labels = [_("draft"), _("published"), _("disabled")];
  let vals = ["DRAFT", "PUBLISHED", "DISABLED"];

  return (
    <div>
      <MultiSwitchContainer
        labels={labels}
        vals={vals}
        name="status"
        selectedValue={status}
        afterChange={setStatus}
      />

      {/* {status === "PUBLISHED" && (
        <>
          <RenderPublishedAt
            selectedStatus={status}
            publishedAt={publishedAt}
            setPublishedAt={setPublishedAt}
            dictionary={dictionary}
          />
        </>
      )}
      {status === "SCHEDULED" && (
        <RenderPublishedAt
          selectedStatus={status}
          publishedAt={publishedAt}
          setPublishedAt={setPublishedAt}
          dictionary={dictionary}
        />
      )} */}
    </div>
  );
};

export default Status;
