import React from "react";
import { CardTool } from "tamtam-components";
import { isUserHasRights } from "utils";
import _ from "i18n";

const WebtoolCard = ({
  webtool,
  navCommunity,
  user,
  language,
  setVisibilityTooltip,
  loadingFavoriteIds,
  favoriteIds,
  setWebToolEdited,
  setWebToolDeleted,
  handleUpdate,
  handleDelete,
  handleSaveFavorite,
  isEnabled,
  isFavorite,
  allowedActions,
}) => {
  const hasRight = isUserHasRights(user, webtool);
  const organizationName =
    webtool.organizationSource && webtool.organizationSource.abbreviation
      ? webtool.organizationSource.abbreviation
      : webtool.organizationSource && webtool.organizationSource.name
      ? webtool.organizationSource.name
      : webtool.organization.abbreviation
      ? webtool.organization.abbreviation
      : webtool.organization.name;
  return (
    <CardTool
      allowedActions={{
        favorite: allowedActions?.favorite ? true : false,
        update:
          allowedActions?.update &&
          (hasRight || webtool.creator.id === user?.id),

        delete: hasRight && allowedActions?.delete ? true : false,
        // update:
        //   allowedActions?.update &&
        //   (hasRight || webtool.creator.id === user?.id) &&
        //   webtool.organizationSource &&
        //   navCommunity.id !== 9,
        // delete:
        //   allowedActions?.delete &&
        //   (hasRight || webtool.creator.id === user?.id) &&
        //   webtool.organizationSource &&
        //   navCommunity.id !== 9,
      }}
      loadingActions={
        {
          // favorite: loadingFavoriteIds.includes(webtool.id),
        }
      }
      isFavorite={isFavorite || favoriteIds?.includes(webtool.id)}
      data={{
        id: webtool.id,
        icon: webtool.icon.label,
        link:
          webtool[`urlName${language}`] ||
          webtool["urlNameFr"] ||
          webtool["urlNameEn"] ||
          webtool["urlNameNl"],
        title: webtool[`title${language}`],
        description: webtool[`description${language}`] ?? "",
        tag: webtool.category[`name${language}`],
        created: webtool.createdAt
          ? `${_("createdThe")} ${webtool.createdAt}`
          : "",
        organization: organizationName ? `${_("by")} ${organizationName}` : "",
        source: webtool.organizationSource ? _("libraryFFF") : null,
      }}
      color={webtool.category.colorCode}
      onUpdate={() => handleUpdate(webtool)}
      onDelete={() => handleDelete(webtool)}
      onAddFavorite={() => handleSaveFavorite(webtool)}
    />
  );
};

export default WebtoolCard;
