import React, { useState } from "react";
import Autosuggest from "react-autosuggest";
import { useQuery } from "react-query";

import { getUserNameForAvatar, addLandaSize } from "utils";

import styles from "./ChainSuggestion.module.scss";
import IconClose from "components/Icons/IconClose";
// import { useAuth } from "@/contexts/auth/use-auth";
// import { useDictionary } from "@/contexts/dictionary/use-dictionary";
import IconPlus from "components/Icons/IconPlus";
import { getAvatars } from "api/user";
import { useSelector } from "react-redux";
import _ from "i18n";

export default function ChainSuggestion(props) {
  // const {
  //   auth: { token, navCommunity },
  // } = useAuth();
  const { token, navCommunity } = useSelector((state) => state.auth);
  const [addingAuthor, setAddingAuthor] = useState(false);
  const [authorInputValue, setAuthorInputValue] = useState("");

  const { data: chainsSuggestions, isFetching: loadingChainSuggestions } =
    useQuery(
      ["getAvatars", authorInputValue, token, navCommunity],
      async () => {
        if (token) {
          try {
            const res = await getAvatars({
              token,
              word: authorInputValue,
              organizationId: navCommunity.id,
            });
            return res.data.data;
          } catch (error) {
            console.error(error);
          }
        }
      }
    );

  let { selectedCommunity, selectedLanguage } = props;

  const nameAttr = `name${
    selectedLanguage.charAt(0).toUpperCase() + selectedLanguage.slice(1)
  }`;

  const toggleAddAuthor = () => {
    setAuthorInputValue("");
    setAddingAuthor(!addingAuthor);
  };

  const handleSelectChain = (newChain) => {
    let avatar = null;
    let avatarUrl = null;
    if (newChain.mediaChain) {
      let media = newChain.mediaChain.filter(
        (item) => item.language === selectedLanguage
      );
      if (media && media.length === 1) {
        avatar = media[0].avatar;
        avatarUrl = media[0].avatarUrl;
      }
    }

    props.onChainChange({
      id: newChain.id,
      signature: {
        title: newChain.company,
        head: newChain.headline,
      },
      company: newChain.company,
      head: newChain.headline,
      enableAvatar: true,
      avatar: avatarUrl,
      avatarUrl: avatarUrl,
      nameFr: newChain.nameFr,
      nameEn: newChain.nameEn,
      nameNl: newChain.nameNl,
      priority: 0,
      isAvatar: true,
    });

    setAddingAuthor(false);
  };

  if (!addingAuthor) {
    if (!selectedCommunity) {
      return null;
    }
    return (
      <div className={styles.addChain} onClick={toggleAddAuthor}>
        <div className={styles.plus}>
          <IconPlus />
        </div>
        <p>{_("add_chain")}</p>
      </div>
    );
  }

  const inputProps = {
    placeholder: _("type_chain"),
    value: authorInputValue,
    onChange: (event, { newValue }) => setAuthorInputValue(newValue),
    autoFocus: true,
    spellCheck: false,
    autoCorrect: "off",
    className: styles.autoSuggestInput,
  };

  return (
    <div>
      <span className={styles.closeCoauthor} onClick={toggleAddAuthor}>
        <IconClose />
      </span>
      <Autosuggest
        multiSection={false}
        suggestions={chainsSuggestions ? chainsSuggestions : []}
        shouldRenderSuggestions={(value) => {
          return value && value.trim().length > 2;
        }}
        onSuggestionsFetchRequested={({ value }) => setAuthorInputValue(value)}
        onSuggestionSelected={(e, { suggestion }) => {
          handleSelectChain(suggestion);
        }}
        getSuggestionValue={(suggestion) => {
          return suggestion.company;
        }}
        renderSuggestion={(suggestion) => {
          let avatarUrl = suggestion.avatarUrl ? suggestion.avatarUrl : null;

          if (suggestion.mediaChain) {
            let media = suggestion.mediaChain.filter(
              (item) => item.language === selectedLanguage
            );
            if (media && media.length === 1) {
              avatarUrl = media[0].avatarUrl;
            }
          }
          return (
            <div className={styles.coauthorSuggestion}>
              {avatarUrl ? (
                <div
                  className={styles.imgWrap}
                  style={{
                    backgroundImage: `url(${addLandaSize(avatarUrl, 240)})`,
                  }}
                ></div>
              ) : (
                <div className={`${styles.imgWrap} ${styles.emptyAvatar}`}>
                  <span style={{ fontSize: "inherit" }}>
                    {getUserNameForAvatar("", suggestion[nameAttr])}
                  </span>
                </div>
              )}

              <div className={styles.content}>
                <h4>{suggestion[nameAttr]}</h4>
              </div>
            </div>
          );
        }}
        inputProps={inputProps}
        renderSuggestionsContainer={({ containerProps, children, query }) => {
          return (
            <div {...containerProps}>
              {loadingChainSuggestions ? (
                <span className={styles.sidebarPlaceholder}>
                  {_("loading_suggestions")}
                </span>
              ) : (
                children
              )}
            </div>
          );
        }}
      />
    </div>
  );
}
