import React from "react";

const IconClose = ({ size = 17, className = "" }) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 17 17"
      fill="none"
    >
      <path
        d="M6.47932 8.49955L0.418184 2.43842C-0.13975 1.88049 -0.13975 0.975897 0.418184 0.418042C0.97612 -0.139892 1.88063 -0.139892 2.43856 0.418042L8.49978 6.47925L14.561 0.417963C15.1189 -0.139972 16.0234 -0.139972 16.5814 0.417963C17.1392 0.975897 17.1392 1.88049 16.5814 2.43834L10.5202 8.49947L16.5814 14.5607C17.1392 15.1186 17.1392 16.0232 16.5814 16.5811C16.0234 17.139 15.1189 17.139 14.561 16.5811L8.49978 10.5198L2.43856 16.5811C1.88071 17.139 0.97612 17.139 0.418184 16.5811C-0.13975 16.0231 -0.13975 15.1186 0.418184 14.5607L6.47932 8.49955Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconClose;
