import axios from "axios";
import { TTP_API_URL } from "../config";
import { throwCatchedError } from "../utils";
import {
  getRequestCancellationToken,
  getRequestConfig,
  generateCancellationTokenSource,
} from "../services/axiosUtils";

let getCollaboratorsCTS;
let getClientsCTS;
let getContactsCTS;
let getUsedArticlesCTS;

export const setAddToLibrary = ({
  token,
  webtool,
  owner,
  organization,
  csScope,
  groups,
  sharedId,
  onProgress,
  specCollaborators,
  specClients,
  specContacts,
}) => {
  const requestUrl = sharedId
    ? `${TTP_API_URL}/media/organization-webtool/${sharedId}`
    : `${TTP_API_URL}/media/organization-webtool`;
  let config = {};
  if (onProgress) {
    config.onUploadProgress = (progressEvent) => {
      let percentCompleted = Math.floor(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      onProgress(percentCompleted);
    };
  }

  let formData = new FormData();
  formData.append("access_token", token);
  formData.append("csScope", csScope.join(","));

  if (organization) formData.append("organization", organization);
  if (owner) formData.append("owner", owner);
  if (webtool) formData.append("webtool", webtool);
  if (specCollaborators)
    formData.append("specCollaborators", specCollaborators.join(","));
  if (specClients) formData.append("specClients", specClients.join(","));
  if (specContacts) formData.append("specContacts", specContacts.join(","));
  if (groups) formData.append("groups", groups.join(","));

  return axios.post(requestUrl, formData, config);
};
export const getSharedWebtools = ({
  token,
  community = null,
  cancellationTokenSource = null,
}) => {
  let requestCancellationToken = getRequestCancellationToken(
    getUsedArticlesCTS,
    cancellationTokenSource
  );

  const fields = ["*", "creator", "tags", "organization", "category", "icon"];
  const sort = [
    {
      property: "createdAt",
      dir: "desc",
    },
  ];

  let requestUrl = `${TTP_API_URL}/media/webtool/get-banned-webtools`;

  let params = {
    access_token: token,
    organization_id: community,
    sort: JSON.stringify(sort),
    fields: fields.join(","),
    from_app: "webtools",
    limit: 200,
    start: 0, //offset
  };

  let requestConfig = getRequestConfig(params, requestCancellationToken);

  return axios.get(requestUrl, requestConfig).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};

export const getClients = ({ token, search }) => {
  let cancellationTokenSource = generateCancellationTokenSource();

  let requestCancellationToken = getRequestCancellationToken(
    getClientsCTS,
    cancellationTokenSource
  );
  getClientsCTS = cancellationTokenSource;

  let requestUrl = `${TTP_API_URL}/organization/folder`;

  let params = {
    access_token: token,
    fields: "id,legalRepresentative",
    start: 0,
    limit: 20,
  };

  if (search) {
    params.filter = JSON.stringify([
      { property: "name", value: search, operator: "like" },
    ]);
  }

  let requestConfig = getRequestConfig(params, requestCancellationToken);
  return axios.get(requestUrl, requestConfig).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};
export const getCollaborators = ({ token, search, organizationId }) => {
  let cancellationTokenSource = generateCancellationTokenSource();
  // getGroupsCTS
  let requestCancellationToken = getRequestCancellationToken(
    getCollaboratorsCTS,
    cancellationTokenSource
  );
  getCollaboratorsCTS = cancellationTokenSource;

  let requestUrl = `${TTP_API_URL}/organization/user`;

  const filter = [
    {
      property: "organization.id",
      value: organizationId,
      operator: "eq",
    },
  ];

  if (search) {
    filter.push({ property: "name", value: search, operator: "like" });
    filter.push({ property: "email.main", value: 1, operator: "eq" });
  }

  let params = {
    access_token: token,
    fields: "*",
    start: 0,
    filter: JSON.stringify(filter),
    limit: 20,
    workspace: "ua",
  };

  let requestConfig = getRequestConfig(params, requestCancellationToken);
  return axios.get(requestUrl, requestConfig).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};
export const getContacts = ({ token, search, organizationId }) => {
  let cancellationTokenSource = generateCancellationTokenSource();

  let requestCancellationToken = getRequestCancellationToken(
    getContactsCTS,
    cancellationTokenSource
  );
  getContactsCTS = cancellationTokenSource;

  let requestUrl = `${TTP_API_URL}/mailing/contact`;
  const filter = [
    { property: "client.id", value: organizationId, operator: "eq" },
    { property: "firstName", value: "", operator: "neq" },
  ];
  if (search) {
    filter.push(
      ...[
        { property: "name", value: search, operator: "like" },
        { property: "email.main", value: 1, operator: "eq" },
      ]
    );
  }

  let params = {
    filter: JSON.stringify(filter),
    access_token: token,
    fields: "*",
    start: 0,
    limit: 20,
  };

  let requestConfig = getRequestConfig(params, requestCancellationToken);
  return axios.get(requestUrl, requestConfig).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};
export const getGroups = ({ token, clientId = null, customFilter = null }) => {
  const requestUrl = `${TTP_API_URL}/mailing/group`;
  let filter = [
    {
      property: "client.id",
      value: clientId,
      operator: "eq",
    },
  ];

  if (customFilter !== null && Array.isArray(customFilter)) {
    filter.push(...customFilter);
  }

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      nolimit: 1,
      fields: "id, name",
      filter: JSON.stringify(filter),
    },
  });
};

// export const removeFromLibrary = (token, webtoolId, organizationId) => {
//   const requestUrl = `${TTP_API_URL}/media/organization-webtool/delete`;

//   var formData = new FormData();

//   formData.append("access_token", token);
//   formData.append("webtool", webtoolId);
//   formData.append("organization", organizationId);
//   formData.append("_method", "delete");

//   return axios.post(requestUrl, formData);
// };
