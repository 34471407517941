import React, { useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import Select from "react-select";
import "suneditor/dist/css/suneditor.min.css";

import Loader from "components/Commun/Loader";
import SunEditor from "suneditor-react";
import _ from "i18n";

import styles from "./RequestEdit.module.scss";
import { useSelector } from "react-redux";
import { SELECT_STYLES } from "config";
import Button from "components/Commun/Button";
import { saveFFFLibraryRequest } from "api";

const RequestEdit = ({ webtool, setWebtool }) => {
  const lanuqge = useSelector((state) => state.params.language);
  const titleAttr = `title${
    lanuqge.charAt(0).toUpperCase() + lanuqge.slice(1)
  }`;
  const actionOptions = [
    { value: "EDIT", label: _("update") },
    { value: "DELETE", label: _("delete") },
  ];
  const auth = useSelector((state) => state.auth);
  const { token } = auth;
  const [isSaving, setIsSaving] = useState(false);
  const [action, setAction] = useState(actionOptions[0]);
  const [content, setContent] = useState("");

  const closeModal = () => {
    setContent("");
    setWebtool(null);
  };

  const validate = () => {
    let errors = [];

    if (action.value === "EDIT" && content.trim().length === 0) {
      errors.push("Content required");
    }

    return errors;
  };

  const saveEdit = () => {
    let errors = validate();
    if (errors && errors.length > 0) {
      let ErrorsContainer = ({ errors }) => (
        <div>
          <span>{_("errors") + " :"}</span>
          <ul>
            {errors.map((e, i) => (
              <li key={i}>{e}</li>
            ))}
          </ul>
        </div>
      );
      toast.error(<ErrorsContainer errors={errors} />, {
        autoClose: true,
      });
      return;
    }

    const title =
      webtool[titleAttr] ||
      webtool["titleFr"] ||
      webtool["titleEn"] ||
      webtool["titleNl"];

    setIsSaving(true);
    saveFFFLibraryRequest(token, {
      id: webtool.id,
      action: action.value,
      objectId: webtool.id,
      title: title,
      content: content.trim(),
      organization: webtool.organizationSource.id,
    })
      .then(async (resp) => {
        setIsSaving(false);
        toast.success(_("successfully_added"));
        closeModal();
      })
      .catch((e) => {
        setIsSaving(false);
        toast.error(_("error"));
      });
  };

  const handlePaste = (event, cleanData, maxCharCount) => {
    let content = cleanData.replace(/\s+style="[^"]*"/gi, "");
    return content.replace(/(<\/?h)([0-1])/gi, "$12");
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={webtool === null ? false : true}
      className={{
        base: styles.modalContent,
        afterOpen: styles.modalContentAfterOpen,
        beforeClose: styles.modalContentBeforeClose,
      }}
      overlayClassName={styles.modalOverlay}
    >
      <div className={styles.modal}>
        <div className={styles.modal_header}>
          <i className={`icon-ttp-newspaper ${styles.modal_icon}`}></i>
          {_("Demande édition webtool")}
        </div>
        <div className={styles.modal_close} onClick={closeModal}>
          <i className="icon-ttp-close"></i>
        </div>

        <div className={`${styles.modal_body}`}>
          <div className="grid-x grid-margin-x">
            <div className={`cell small-12 medium-6 large-4 ${styles.formRow}`}>
              <label className={styles.formLabel}>Type de demande</label>
              <Select
                styles={SELECT_STYLES}
                options={actionOptions}
                value={action}
                onChange={(e) => setAction(e)}
              />
            </div>
          </div>

          <label className={styles.formLabel}>Votre message</label>
          <div className={styles.editorContainer}>
            <SunEditor
              // placeholder={_("cv_write_here")}
              setOptions={{
                attributesWhitelist: {
                  all: "style",
                  input: "checked",
                },
                height: "auto",
                minHeight: "250px",
                showPathLabel: false,
                resizingBar: false,
                imageUrlInput: false,
                imageWidth: "75%",
                formats: ["p", "blockquote", "h2", "h3", "h4", "h5", "h6"],
                buttonList: [
                  [
                    "undo",
                    "redo",
                    "formatBlock",
                    "bold",
                    "underline",
                    "italic",
                    "strike",
                    "outdent",
                    "indent",
                    "align",
                    "list",
                    "table",
                  ],
                ],
              }}
              onChange={(c) => setContent(c)}
              onPaste={handlePaste}
            />
          </div>
        </div>
        <div className={styles.controls}>
          <Button onClick={closeModal} variant="default">
            {_("cancel")}
          </Button>
          {isSaving ? (
            <Button
              variant="primary"
              style={{
                paddingTop: "15px",
                paddingBottom: "15px",
              }}
              className={styles.controls__ok}
            >
              <Loader
                style={{
                  height: "10px",
                }}
                color={"#fff"}
              />
            </Button>
          ) : (
            <Button onClick={saveEdit} variant="primary">
              {_("save")}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default RequestEdit;
