import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCategories, getFavorites, saveFavorite } from "../../api";
import { CardTool } from "tamtam-components";
import _ from "../../i18n";
import classnames from "classnames";
import styles from "./MySpace.module.scss";
import NotFound from "../Commun/NotFound/NotFound";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { SHARING_OPTIONS } from "../../config";
import WebtoolCard from "components/Commun/WebtoolCard";

function MyFavorites() {
  const params = useSelector((state) => state.params);
  const { user, navCommunity, token } = useSelector((state) => state.auth);

  const [myFavoriesWebtools, setMyFavoriesWebtools] = useState(null);
  const [categoriesTags, setCategoriesTags] = useState([]);
  const [webToolDeleted, setWebToolDeleted] = useState(null);
  const [webToolEdited, setWebToolEdited] = useState(null);
  const [categories, setCategories] = useState(null);
  const [loadingFavoriteIds, setLoadingFavoriteIds] = useState([]);

  const [filter, setFilter] = useState({
    lng: params?.language,
    categoryId: null,
    keyWord: "",
  });

  const lng = filter.lng.charAt(0).toUpperCase() + filter.lng.slice(1);

  const allowedActions =
    user &&
    (user.isUaAdmin ||
      (user.role.typeStatus === "LEGAL_REPRESENTATIVE" &&
        user.role.type === "MANAGER" &&
        user.role.organization.id === navCommunity.id));

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const fetchCategories = async (keyWord = "") => {
    let filter = { search: keyWord, lng: params?.language };
    setCategories(null);
    await getCategories({ token: token, filter }).then((response) => {
      setCategories(response.data.data);
    });
  };
  const myFavories = useQuery(
    ["getFavorites", token, navCommunity],
    async () => {
      if (token && navCommunity) {
        const webtools = await getFavorites({
          token: token,
          filter,
          community: navCommunity.id,
        })
          .then((res) => {
            return res.data.data ? res.data.data : [];
          })
          .catch((error) => {
            return [];
          });
        const arr = webtools
          .filter((w) => w[`title${lng}`]?.length > 0)
          .map((w) => w.category);
        const categories = arr.filter(
          (x, i) =>
            arr.findIndex((object) => {
              return object.id === x.id;
            }) === i
        );

        setCategoriesTags(categories);
        return webtools;
      }
    }
  );

  useEffect(() => {
    if (myFavories.data) {
      const webtools = myFavories.data.filter((webtool) => {
        let toReturn = true && webtool[`title${lng}`];

        if (filter.categoryId) {
          toReturn = webtool.category.id === filter.categoryId;
        }

        if (toReturn && filter.keyWord) {
          toReturn = webtool[`title${lng}`]
            .toLowerCase()
            .includes(filter.keyWord.toLowerCase());
        }
        return toReturn;
      });
      setMyFavoriesWebtools(webtools);
    }
  }, [myFavories.data, filter]);

  useEffect(() => {
    fetchCategories();
  }, []);

  const editWebTool = (webtool) => {
    setWebToolEdited(webtool);
  };

  const deleteWebTool = (webtool) => {
    setWebToolDeleted(webtool);
  };

  const updateLanguageFilter = (lng) => {
    setFilter((previousState) => {
      return { ...previousState, lng };
    });
  };

  const updateKeyWordFilter = (word) => {
    setFilter((previousState) => {
      return { ...previousState, keyWord: word.target.value };
    });
  };

  const updateCategoryFilter = (category) => {
    if (filter.categoryId === category.id) {
      category = { id: null };
    }
    setFilter((previousState) => {
      return { ...previousState, categoryId: category.id };
    });
  };

  const handleSaveFavorite = async (webtool) => {
    if (!loadingFavoriteIds.includes(webtool.id)) {
      setLoadingFavoriteIds([...loadingFavoriteIds, webtool.id]);
    }
    await saveFavorite({
      id: webtool.id,
      token: token,
      organizationId: navCommunity.id,
    })
      .then((res) => {
        myFavories.refetch();
        toast.success(_("saveSuccess"));
      })
      .catch((e) => {
        toast.error(_("savingError"), { autoClose: true });
      })
      .finally(() => {
        setLoadingFavoriteIds(
          loadingFavoriteIds.filter((f) => f !== webtool.id)
        );
      });
  };

  const renderMySpaceFilter = () => {
    return (
      <div className={styles.filter}>
        <div className={classnames(styles.search_frame, "grid-x")}>
          {["fr", "en", "nl"].map((item, i) => {
            return (
              <div
                className={classnames(
                  styles.lng_item,
                  filter.lng === item ? styles.activated : ""
                )}
                onClick={() => updateLanguageFilter(item)}
              >
                {item}
              </div>
            );
          })}
          <div className={classnames(styles.search, "cell small-12 medium-5")}>
            <i className="icon icon-magnifier" />
            <input
              id="searchInput"
              type="text"
              value={filter.keyWord}
              placeholder={_("Search...")}
              onChange={(event) => updateKeyWordFilter(event)}
            />
          </div>
        </div>
        <div className={classnames(styles.tags, "grid-x")}>
          <div
            className={classnames(
              styles.tag_item,
              !filter.categoryId ? styles.activated : ""
            )}
            onClick={() => {
              setFilter((previousState) => {
                return { ...previousState, categoryId: null };
              });
            }}
          >
            {_("all")}
          </div>
          {categoriesTags.map((item, i) => (
            <div
              className={classnames(
                styles.tag_item,
                filter.categoryId === item.id ? styles.activated : ""
              )}
              style={{
                borderColor: item.colorCode,
                backgroundColor:
                  filter.categoryId === item.id ? item.colorCode : "",
              }}
              onClick={() => updateCategoryFilter(item)}
            >
              {item[`name${lng}`]}
            </div>
          ))}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (
      navCommunity &&
      !navCommunity.webtoolsPreferences?.source?.includes("MY_LIBRARY")
    ) {
      window.location.href = "/";
    }
  }, [navCommunity]);

  let cardsFetching = [];
  for (let j = 0; j < 8; j++) {
    cardsFetching.push(
      <div
        className="cell small-12 medium-4 large-3"
        style={{ marginTop: "20px" }}
      >
        <div key={j} className="card_tool_fetching gradiant" />
      </div>
    );
  }
  return (
    <div className={classnames(styles.my_space)}>
      <div className={styles.header}>
        <span>{_("myFavorites")}</span>
      </div>
      {renderMySpaceFilter()}
      <div className={"grid-x"}>
        {myFavories.isFetching ? (
          cardsFetching
        ) : myFavoriesWebtools?.length === 0 ? (
          <NotFound />
        ) : (
          myFavoriesWebtools?.map((webtool) => {
            return webtool[`title${lng}`]?.length > 0 ? (
              <div
                className="cell small-12 medium-6 large-3  grid_card"
                style={{ marginTop: "20px" }}
              >
                <WebtoolCard
                  webtool={webtool}
                  user={user}
                  language={lng}
                  allowedActions={{
                    favorite: true,
                  }}
                  loadingFavoriteIds={loadingFavoriteIds}
                  isFavorite={true}
                  handleSaveFavorite={() => handleSaveFavorite(webtool)}
                  handleUpdate={() => editWebTool(webtool)}
                  handleDelete={() => deleteWebTool(webtool)}
                />
                {/* <CardTool
                  toolOptions={SHARING_OPTIONS}
                  data={{
                    id: webtool.id,
                    title: webtool[`title${lng}`],
                    link: webtool[`urlName${lng}`],
                    description: webtool[`description${lng}`] ?? "",
                    tag: webtool.category[`name${lng}`],
                    organization: webtool.organization.name.toLowerCase(),
                    icon: webtool.icon?.label,
                    created: webtool.createdAt
                      ? `${_("createdThe")} ${webtool.createdAt}`
                      : "",
                    scope: webtool.csScope.includes("PUBLIC")
                      ? "public"
                      : "shared",
                  }}
                  allowed={allowedActions || webtool.creator.id === user.id}
                  allowedActions={{
                    favorite: true,
                    more: false, //allowedActions || webtool.creator.id === user.id,
                  }}
                  color={
                    webtool.category?.colorCode
                      ? webtool.category.colorCode
                      : "#29394D"
                  }
                  onReach={() => openInNewTab(webtool[`urlName${lng}`])}
                  loadingActions={{
                    favorite: loadingFavoriteIds.includes(webtool.id),
                  }}
                  isFavorite={true}
                  onAddFavorite={() => handleSaveFavorite(webtool)}
                  onUpdate={() => editWebTool(webtool)}
                  onDelete={() => deleteWebTool(webtool)}
                /> */}
              </div>
            ) : (
              ""
            );
          })
        )}
      </div>
    </div>
  );
}

export default MyFavorites;
