import React from "react";
const RightBloc = ({
  title,
  subTitle,
  description,
  logoUrl,
  logoWidth = "",
}) => {
  return (
    <div className="recommanded_frame_fiche">
      <div className="aside_frame_title">
        <span>{title}</span>
        <br />
        {subTitle}
      </div>
      <div className="frame-logo">
        <img src={logoUrl} alt={title} width={logoWidth} />
      </div>
      <p>{description}</p>
    </div>
  );
};

export default RightBloc;
