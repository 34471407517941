import Lottie from "react-lottie";
import { NavLink } from "react-router-dom";
import { TiArrowForward } from "react-icons/ti";
import _ from "../../../i18n";

import * as error404Data from "./lottie-animations/error-404.json";
import * as nothingFoundData from "./lottie-animations/nothing-found.json";
import * as accessDenied from "./lottie-animations/access-denied.json";

function NotFound2({
  mainClass,
  children,
  text,
  subText,
  errorPageType,
  buttonIgnored,
}) {
  let defaultOptions = {
    loop: true,
    autoplay: true,
  };
  let renderedTxt;

  switch (errorPageType) {
    case "ERROR_404":
      renderedTxt = (
        <div className="not_found_v2_main">
          <Lottie
            options={{ ...defaultOptions, animationData: error404Data }}
            height={360}
            isClickToPauseDisabled={true}
          />
          <h2>{_("Oops")}</h2>
          <h5>{_("getLost")}</h5>
          <p>{_("pageNotFound")}</p>
          {!buttonIgnored ? (
            <button className="btn secondary goBack">
              <i className="icon-arrow-left" /> &nbsp;&nbsp;&nbsp;&nbsp;
              <NavLink to="/">{_("goBack")}</NavLink>
            </button>
          ) : (
            ""
          )}
        </div>
      );
      break;

    case "ACCESS_DENIED":
      renderedTxt = (
        <div className="not_found_v2_main">
          <Lottie
            options={{ ...this.defaultOptions, animationData: accessDenied }}
            height={360}
            isClickToPauseDisabled={true}
          />
          <h2>{_("Oops")}</h2>
          {subText && <h5>{_(subText)}</h5>}
          <p>{_(text)}</p>
          {/*<p>{_("accessDenied")}</p>*/}
          {!this.props.buttonIgnored ? (
            <button
              className="btn secondary"
              style={{ marginLeft: 12, marginTop: 10 }}
            >
              <i className="icon-arrow-left" /> &nbsp;&nbsp;&nbsp;&nbsp;
              <NavLink to="/">{_("goBack")}</NavLink>
            </button>
          ) : (
            ""
          )}
        </div>
      );
      break;
    case "NOTHING_FOUND":
      renderedTxt = (
        <div className="not_found_v2_main">
          <Lottie
            options={{ ...this.defaultOptions, animationData: accessDenied }}
            height={360}
            isClickToPauseDisabled={true}
          />
          <h2>{_("Oops")}</h2>
          {subText && <h5>{_(subText)}</h5>}
          <p>{_(text)}</p>
          {this.props.redirect ? (
            <button
              className="btn secondary"
              onClick={this.props.redirect}
              style={{ marginLeft: 12, marginTop: 10 }}
            >
              <TiArrowForward /> &nbsp;&nbsp;
              {_(this.props.buttonText)}
            </button>
          ) : (
            ""
          )}
        </div>
      );
      break;

    default:
      renderedTxt = (
        <div className="not_found_v2_main">
          <Lottie
            options={{
              ...this.defaultOptions,
              animationData: nothingFoundData,
            }}
            height={360}
            isClickToPauseDisabled={true}
          />
          <h2>{_("noResult")}...</h2>
          <p>{_(text || "nothingFound")}</p>
        </div>
      );
      break;
  }

  return (
    <div className={`not_found_v2 containar ${mainClass}`}>
      {children || renderedTxt}
    </div>
  );
}

export default NotFound2;
