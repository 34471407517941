import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import debounce from "lodash/debounce";
import { toast } from "react-toastify";

import { PlusCircleOutlined, CloseOutlined } from "@ant-design/icons";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ModalConfirm } from "tamtam-components";
import { Editor } from "react-draft-wysiwyg";
import AsyncSelect from "react-select/async";
import { ChromePicker } from "react-color";
import { Select, Switch } from "antd";
import _ from "../../i18n";

import {
  deleteCategory,
  deleteWebtool,
  getTags,
  getWebtoolIcons,
  saveCategory,
  saveWebtool,
} from "../../api";
import { selectStyle } from "./ReactSelectStyle";
import styles from "./ManagementSpace.module.scss";

import GroupsSelect from "../Library/GroupsSelect";
import CollaboratorsSelect from "../Library/CollaboratorsSelect";
import ClientSelect from "../Library/ClientSelect";
import ContactSelect from "../Library/ContactSelect";
import LoaderIcon from "../Icons/LoaderIcon";

const scopes = [
  "PUBLIC",
  "ALL_COLLABORATORS",
  "ALL_CLIENTS",
  "ALL_CONTACTS",
  // "SPEC_COLLABORATOR",
  // "SPEC_CLIENT",
  // "SPEC_CONTACT",
  // "GROUP",
];

function AddCategory(props) {
  const [isSaving, setIsSaving] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [editorStateFr, setEditorStateFr] = useState("");
  const [editorStateEn, setEditorStateEn] = useState("");
  const [editorStateNl, setEditorStateNl] = useState("");
  const [inputTitleStateFr, setInputTitleStateFr] = useState("");
  const [inputTitleStateEn, setInputTitleStateEn] = useState("");
  const [inputTitleStateNl, setInputTitleStateNl] = useState("");
  const [inputUrlStateFr, setInputUrlStateFr] = useState("");
  const [inputUrlStateEn, setInputUrlStateEn] = useState("");
  const [inputUrlStateNl, setInputUrlStateNl] = useState("");
  const [selectCatgory, onChangeSelectCategory] = useState("");
  const [uploadClicked, setUploadIsClicked] = useState(false);
  const [tags, setTagsState] = useState([]);
  const [webToolsIcons, setWebToolsIconsState] = useState([]);
  const [iconSelected, selectIcon] = useState(null);
  const [color, setColor] = useState({
    rgb: { r: "0", g: "0", b: "0", a: "1" },
  });
  const [titleCurrentTab, setTitleCurrentTab] = useState("FR");
  const [urlCurrentTab, setUrlCurrentTab] = useState("FR");
  const [descriptionCurrentTab, setDescriptionCurrentTab] = useState("FR");
  const [searchValue, setSearchValue] = useState("");
  const [switchLanguages, setSwitchLanguages] = useState([
    "ALL",
    "FR",
    "EN",
    "NL",
  ]);

  const [checkedItems, setCheckedItems] = useState(["PUBLIC"]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [specCollaborators, setSpecCollaborators] = useState([]);
  const [specClients, setSpecClients] = useState([]);
  const [specContacts, setSpecContacts] = useState([]);

  const auth = useSelector((state) => state.auth);
  const lng = useSelector((state) => state.params.language);
  const { token } = auth;

  const {
    setWebToolDeleted,
    webToolDeleted,
    setWebToolEdited,
    webToolEdited,
    setCategoryDeleted,
    categoryDeleted,
    setCategoryEdited,
    categoryEdited,
    fetchCategories,
    categories,
    updateWebTools,
  } = props;

  const titleAttr = `title${lng.charAt(0).toUpperCase() + lng.slice(1)}`;
  const nameAttr = `name${lng.charAt(0).toUpperCase() + lng.slice(1)}`;
  const lngs = ["Fr", "En", "Nl"];

  const dispatch = useDispatch();

  const saveWebTool = () => {
    let category = categories.filter(function (c) {
      return c.id === selectCatgory;
    });
    let descriptionFr =
      editorStateFr &&
      JSON.stringify(convertToRaw(editorStateFr.getCurrentContent()));
    let descriptionEn =
      editorStateEn &&
      JSON.stringify(convertToRaw(editorStateEn.getCurrentContent()));
    let descriptionNl =
      editorStateNl &&
      JSON.stringify(convertToRaw(editorStateNl.getCurrentContent()));

    let itemResult = {
      id: webToolEdited ? webToolEdited.id : null,
      token: auth.token,
      lng: lng,
      organization: auth.navCommunity,
      category: category[0],
      titleFr: inputTitleStateFr,
      titleEn: inputTitleStateEn,
      titleNl: inputTitleStateNl,
      descriptionFr: descriptionFr,
      descriptionEn: descriptionEn,
      descriptionNl: descriptionNl,
      urlFr: inputUrlStateFr,
      urlEn: inputUrlStateEn,
      urlNl: inputUrlStateNl,
      urlNameFr: inputUrlStateFr,
      urlNameEn: inputUrlStateEn,
      urlNameNl: inputUrlStateNl,
      tags: tags,
      creator: auth.user,
      icon: iconSelected,
      languages: [lng.toUpperCase()],
      csScope: checkedItems,
      specCollaborators: specCollaborators.map((el) => el.id),
      specClients: specClients.map((el) => el.id),
      specContacts: specContacts.map((el) => el.id),
      groups: selectedGroups.map((group) => group.id),
    };
    setIsSaving(true);
    saveWebtool(itemResult)
      .then((response) => {
        updateWebTools(itemResult, true);
        setIsClicked(false);
        webToolEdited
          ? toast.success(_("webToolUpdated"))
          : toast.success(_("webToolSaved"));
        clearState();
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  function componentToHex(c) {
    let hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  }

  function rgbToHex(r, g, b) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
  }

  function hex2rgba(hex, alpha = 1) {
    const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
    return { rgb: { r: r, g: g, b: b, a: alpha } };
  }
  const isValidForm = () => {
    return (
      iconSelected &&
      inputTitleStateFr !== "" &&
      inputTitleStateEn !== "" &&
      inputTitleStateNl !== ""
    );
  };
  const saveCategorie = () => {
    setIsSaving(true);
    saveCategory({
      id: categoryEdited ? categoryEdited.id : null,
      token: auth.token,
      lng: lng,
      organization: auth.navCommunity,
      titleFr: inputTitleStateFr,
      titleEn: inputTitleStateEn,
      titleNl: inputTitleStateNl,
      creator: auth.user,
      icon: iconSelected,
      color: rgbToHex(color.rgb.r, color.rgb.g, color.rgb.b),
      languages: [lng.toUpperCase()],
    })
      .then((response) => {
        setIsSaving(false);
        fetchCategories();
        setIsClicked(false);
        categoryEdited
          ? toast.success(_("categoryUpdated"))
          : toast.success(_("categorySaved"));
        clearState();
      })
      .finally(() => setIsSaving(false));
  };
  let filter = [];
  const loadTagsSuggestions = (inputValue, callback) => {
    if (null !== inputValue && inputValue.length > 0) {
      filter = [
        {
          property: `name${lng.charAt(0).toUpperCase() + lng.slice(1)}`,
          value: inputValue,
          operator: "like",
        },
      ];
    }
    getTags({
      token: auth.token,
      lng: lng,
      filter: filter,
    })
      .then((result) => {
        callback(result.data.data);
      })
      .catch(() => callback([]));
  };

  const loadTagsSuggestionsDebounded = debounce(loadTagsSuggestions, 300);

  useEffect(() => {
    if (token) {
      getWebtoolIcons(auth.token).then((response) => {
        if (response.data.data && response.data.data.length > 0) {
          setWebToolsIconsState(response.data.data);
        }
      });
    }
  }, [token]);

  // useEffect(() => {
  //   if (webToolEdited) {
  //     setIsClicked(true);
  //     webToolEdited.descriptionFr &&
  //       setEditorStateFr(
  //         EditorState.createWithContent(
  //           convertFromRaw(JSON.parse(webToolEdited.descriptionFr))
  //         )
  //       );
  //     webToolEdited.descriptionEn &&
  //       setEditorStateEn(
  //         EditorState.createWithContent(
  //           convertFromRaw(JSON.parse(webToolEdited.descriptionEn))
  //         )
  //       );
  //     webToolEdited.descriptionNl &&
  //       setEditorStateNl(
  //         EditorState.createWithContent(
  //           convertFromRaw(JSON.parse(webToolEdited.descriptionNl))
  //         )
  //       );
  //     let lngs = ["ALL", "FR", "EN", "NL"];
  //     if (webToolEdited.titleFr?.length === 0) {
  //       lngs = lngs.filter((l) => "FR" !== l);
  //       setSwitchLanguages(lngs);
  //     }
  //     if (webToolEdited.titleNl?.length === 0) {
  //       lngs = lngs.filter((l) => "NL" !== l);
  //       setSwitchLanguages(lngs);
  //     }
  //     if (webToolEdited.titleEn?.length === 0) {
  //       lngs = lngs.filter((l) => "EN" !== l);
  //       setSwitchLanguages(lngs);
  //     }
  //     if (lngs.length < 4) {
  //       lngs = lngs.filter((l) => "ALL" !== l);
  //       setSwitchLanguages(lngs);
  //     }

  //     setInputTitleStateFr(webToolEdited.titleFr);
  //     setInputTitleStateEn(webToolEdited.titleEn);
  //     setInputTitleStateNl(webToolEdited.titleNl);
  //     setInputUrlStateFr(webToolEdited.urlNameFr);
  //     setInputUrlStateEn(webToolEdited.urlNameEn);
  //     setInputUrlStateNl(webToolEdited.urlNameNl);
  //     onChangeSelectCategory(webToolEdited.category?.id);
  //     setTagsState(webToolEdited.tags);
  //     selectIcon(webToolEdited.icon);
  //     setCheckedItems(webToolEdited.csScope);
  //   }
  // }, [webToolEdited]);

  useEffect(() => {
    if (categoryEdited) {
      setIsClicked(true);
      setInputTitleStateFr(categoryEdited.nameFr);
      setInputTitleStateEn(categoryEdited.nameEn);
      setInputTitleStateNl(categoryEdited.nameNl);
      selectIcon(categoryEdited.icon);
      setColor(hex2rgba(categoryEdited.colorCode));
    }
  }, [categoryEdited]);

  const clearState = () => {
    selectIcon(null);
    setInputTitleStateFr("");
    setInputTitleStateEn("");
    setInputTitleStateNl("");
    setInputUrlStateFr("");
    setInputUrlStateEn("");
    setInputUrlStateNl("");
    setEditorStateFr("");
    setEditorStateEn("");
    setEditorStateNl("");
    onChangeSelectCategory("");
    setTagsState([]);
    setWebToolEdited(null);
    setCategoryEdited(null);
    setSearchValue("");
    setColor({ rgb: { r: "0", g: "0", b: "0", a: "1" } });
    setSwitchLanguages(["ALL", "FR", "EN", "NL"]);
    setCheckedItems(["PUBLIC"]);
  };

  const deleteCard = () => {
    if (webToolDeleted) {
      deleteWebtool({ token: auth.token, webtoolId: webToolDeleted.id }).then(
        (response) => {
          updateWebTools(webToolDeleted, false);
          toast.success(_("webToolDeleted"));
          dispatch(setWebToolDeleted(null));
        }
      );
    } else if (categoryDeleted) {
      deleteCategory({
        token: auth.token,
        categoryId: categoryDeleted.id,
      }).then((response) => {
        fetchCategories();
        toast.success(_("categoryDeleted"));
        dispatch(setCategoryDeleted(null));
      });
    }
  };
  const onChangeLanguage = (Language, activate) => {
    if (activate === true) {
      setSwitchLanguages([].concat(switchLanguages, Language.toString()));
      if (
        Language === "ALL" ||
        (switchLanguages.length === 2 && !switchLanguages.includes("ALL"))
      ) {
        setSwitchLanguages(["ALL", "FR", "EN", "NL"]);
      }
    } else {
      if (switchLanguages.length > 1 && Language !== "ALL") {
        let lngs = switchLanguages.filter((l) => Language !== l && l !== "ALL");
        setSwitchLanguages(lngs);

        if (titleCurrentTab === Language) {
          setTitleCurrentTab(lngs[0]);
          setUrlCurrentTab(lngs[0]);
          setDescriptionCurrentTab(lngs[0]);
        }

        switch (Language) {
          case "FR":
            setInputTitleStateFr("");
            setInputUrlStateFr("");
            break;
          case "EN":
            setInputTitleStateEn("");
            setInputUrlStateEn("");
            break;
          case "NL":
            setInputTitleStateNl("");
            setInputUrlStateNl("");
            break;
        }
      }
    }
  };

  const handleChange = (scope) => {
    let tab = [];
    switch (scope) {
      case "PUBLIC":
        tab = ["PUBLIC"];
        setSpecCollaborators([]);
        setSpecClients([]);
        setSpecContacts([]);
        break;
      case "ALL_COLLABORATORS":
        if (checkedItems.includes("ALL_COLLABORATORS")) {
          tab = [...checkedItems.filter((el) => el !== "ALL_COLLABORATORS")];
        } else {
          tab = [
            ...checkedItems.filter(
              (el) => el !== "PUBLIC" && el !== "SPEC_COLLABORATOR"
            ),
            "ALL_COLLABORATORS",
          ];
          setSpecCollaborators([]);
        }
        break;
      case "ALL_CLIENTS":
        if (checkedItems.includes("ALL_CLIENTS")) {
          tab = [...checkedItems.filter((el) => el !== "ALL_CLIENTS")];
        } else {
          tab = [
            ...checkedItems.filter(
              (el) => el !== "PUBLIC" && el !== "SPEC_CLIENT"
            ),
            "ALL_CLIENTS",
          ];
          setSpecClients([]);
        }
        break;
      case "ALL_CONTACTS":
        if (checkedItems.includes("ALL_CONTACTS")) {
          tab = [...checkedItems.filter((el) => el !== "ALL_CONTACTS")];
        } else {
          tab = [
            ...checkedItems.filter(
              (el) => el !== "PUBLIC" && el !== "SPEC_CONTACT"
            ),
            "ALL_CONTACTS",
          ];
          setSpecContacts([]);
        }
        break;
      case "SPEC_COLLABORATOR":
        if (checkedItems.includes("SPEC_COLLABORATOR")) {
          tab = [...checkedItems.filter((el) => el !== "SPEC_COLLABORATOR")];
          setSpecCollaborators([]);
        } else {
          tab = [
            ...checkedItems.filter(
              (el) => el !== "PUBLIC" && el !== "ALL_COLLABORATORS"
            ),
            "SPEC_COLLABORATOR",
          ];
        }
        break;
      case "SPEC_CLIENT":
        if (checkedItems.includes("SPEC_CLIENT")) {
          tab = [...checkedItems.filter((el) => el !== "SPEC_CLIENT")];
          setSpecClients([]);
        } else {
          tab = [
            ...checkedItems.filter(
              (el) => el !== "PUBLIC" && el !== "ALL_CLIENTS"
            ),
            "SPEC_CLIENT",
          ];
        }
        break;
      case "SPEC_CONTACT":
        if (checkedItems.includes("SPEC_CONTACT")) {
          tab = [...checkedItems.filter((el) => el !== "SPEC_CONTACT")];
          setSpecContacts([]);
        } else {
          tab = [
            ...checkedItems.filter(
              (el) => el !== "PUBLIC" && el !== "ALL_CONTACTS"
            ),
            "SPEC_CONTACT",
          ];
        }
        break;
      case "GROUP":
        if (checkedItems.includes("GROUP")) {
          tab = [...checkedItems.filter((el) => el !== "GROUP")];
          setSelectedGroups([]);
        } else {
          tab = [
            ...checkedItems.filter((el) => el !== "PUBLIC" && el !== "GROUP"),
            "GROUP",
          ];
        }
        break;
    }

    if (tab.length === 0) {
      tab = ["PUBLIC"];
    }
    setCheckedItems(tab);
  };

  const Icons = () => {
    const filteredItems = webToolsIcons.filter((item) => {
      return (
        (item[titleAttr] &&
          item[titleAttr].toLowerCase().includes(searchValue.toLowerCase())) ||
        (item.label &&
          item.label.toLowerCase().includes(searchValue.toLowerCase())) ||
        (item.tags &&
          item.tags.some(
            (tag) =>
              tag[nameAttr] &&
              tag[nameAttr].toLowerCase().includes(searchValue.toLowerCase())
          ))
      );
    });
    return (
      <div
        className={classnames(
          styles.add_modal_icons,
          uploadClicked ? styles.active : ""
        )}
      >
        <div className={styles.modal_icons_content}>
          <CloseOutlined
            onClick={() => setUploadIsClicked(false)}
            style={{
              fontSize: "20px",
              display: "flex",
              justifyContent: "end",
              margin: "1.5rem 1.5rem 0 0",
            }}
          />

          <div
            className={classnames(
              " grid-x grid-margin-x",
              styles.filter_icons_container
            )}
          >
            <div className={classnames(styles.filter_icons, `cell small-12`)}>
              <i className="icon icon-magnifier" />
              <input
                type="text"
                value={searchValue}
                onChange={({ target }) => setSearchValue(target.value)}
                //  disabled={webtoolIcons.fetching}
                placeholder={"Search..."}
              />
            </div>
          </div>
          <div
            className="grid-x"
            style={{ padding: "20px 20px 0 20px", justifyContent: "center" }}
          >
            {filteredItems.map((item) => (
              <li
                key={item.id}
                id={`webtool-icon-${item.id}`}
                className={classnames(
                  "cell small-12 medium-4 large-3",
                  styles.icons_item
                )}
                //   className="card-item cell small-6 medium-3"
                onClick={() => {
                  selectIcon(item);
                  setUploadIsClicked(false);
                }}
              >
                <i className={`icon webtool-${item.label}`} />
                <span title={item[titleAttr] || item.label}>
                  {(item[titleAttr].length < 17
                    ? item[titleAttr]
                    : item[titleAttr].substring(0, 14) + "...") ||
                    (item.label.length < 16
                      ? item.label
                      : item.label.substring(0, 13) + "...")}
                </span>
              </li>
            ))}
          </div>
        </div>
      </div>
    );
  };

  let enableSaveWebTool =
    iconSelected &&
    (switchLanguages.includes("FR")
      ? inputTitleStateFr && inputUrlStateFr
      : true) &&
    (switchLanguages.includes("EN")
      ? inputTitleStateEn && inputUrlStateEn
      : true) &&
    (switchLanguages.includes("NL")
      ? inputTitleStateNl && inputUrlStateNl
      : true) &&
    selectCatgory &&
    props.activeTab === "WEBTOOLS";
  let enableSaveCategory =
    props.activeTab === "CATEGORIES" &&
    iconSelected &&
    inputTitleStateFr &&
    inputTitleStateEn &&
    inputTitleStateNl;
  const languages = switchLanguages.filter((l) => l !== "ALL");

  return (
    <div>
      {!props.hide && (
        <div className={styles.addButton} onClick={() => setIsClicked(true)}>
          <PlusCircleOutlined style={{ fontSize: "27px" }} />
          <span
            style={{
              marginRight: "1rem",
              textAlign: "center",
              width: "100%",
              textTransform: "uppercase",
            }}
          >
            ADD category
          </span>
        </div>
      )}

      <div
        className={classnames(styles.add_modal, isClicked ? styles.active : "")}
      >
        <div className={styles.modal_content}>
          <CloseOutlined
            onClick={() => {
              setIsClicked(false);
              clearState();
            }}
            style={{
              fontSize: "20px",
              display: "flex",
              justifyContent: "end",
              margin: "1.5rem 1.5rem 0 0",
            }}
          />
          {
            <div className={styles.content_inputs}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "30px",
                }}
              >
                <div
                  className={styles.select_icon}
                  onClick={() => setUploadIsClicked(true)}
                >
                  <div className={styles.icon}>
                    {iconSelected ? (
                      <span
                        className={styles.icon_selected}
                        title={iconSelected[titleAttr] || iconSelected.label}
                        style={{
                          color: `rgba(${color.rgb?.r}, ${color.rgb?.g}, ${color.rgb?.b}, ${color.rgb?.a})`,
                        }}
                      >
                        <i className={`icon webtool-${iconSelected.label}`} />
                        <span>
                          {(iconSelected[titleAttr]?.length < 17
                            ? iconSelected[titleAttr]
                            : iconSelected[titleAttr]?.substring(0, 14) +
                              "...") ||
                            (iconSelected.label?.length < 16
                              ? iconSelected.label
                              : iconSelected.label?.substring(0, 13) + "...")}
                        </span>
                      </span>
                    ) : (
                      <span>
                        {_("select")} {_("icon")} *
                      </span>
                    )}
                  </div>
                </div>

                {iconSelected ? (
                  <div>
                    <ChromePicker
                      color={color.rgb}
                      onChangeComplete={setColor}
                      disableAlpha={true}
                      width={150}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className={styles.inputs}>
                <ul>
                  <div>
                    <li>
                      <li>
                        <label>{_("title")} FR *</label>
                        <input
                          className="cell small-8"
                          type="text"
                          name="titleFr"
                          value={inputTitleStateFr}
                          onChange={(e) => setInputTitleStateFr(e.target.value)}
                        />
                      </li>
                    </li>
                    <li>
                      <li>
                        <label>{_("title")} EN *</label>
                        <input
                          className="cell small-8"
                          type="text"
                          name="titleEn"
                          value={inputTitleStateEn}
                          onChange={(e) => setInputTitleStateEn(e.target.value)}
                        />
                      </li>
                    </li>
                    <li>
                      <li>
                        <label>{_("title")} NL *</label>
                        <input
                          className="cell small-8"
                          type="text"
                          name="titleNl"
                          value={inputTitleStateNl}
                          onChange={(e) => setInputTitleStateNl(e.target.value)}
                        />
                      </li>
                    </li>
                  </div>
                  <li
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      marginTop:
                        props.activeTab === "WEBTOOLS" ? "3rem" : "5rem",
                    }}
                  >
                    <div
                      className={classnames(
                        styles.save_butt,
                        !isValidForm() ? styles.disable : ""
                      )}
                      onClick={() => {
                        if (isValidForm()) {
                          saveCategorie();
                        }
                      }}
                    >
                      {isSaving ? (
                        <LoaderIcon />
                      ) : categoryEdited ? (
                        _("update")
                      ) : (
                        _("save")
                      )}
                    </div>
                    <div
                      className={styles.cancel_butt}
                      onClick={() => {
                        setIsClicked(false);
                        clearState();
                      }}
                    >
                      {_("cancel")}
                    </div>
                  </li>
                </ul>
              </div>
              <ModalConfirm
                type={"delete"}
                isOpen={!!webToolDeleted || !!categoryDeleted}
                onCancel={() =>
                  webToolDeleted
                    ? dispatch(setWebToolDeleted(null))
                    : dispatch(setCategoryDeleted(null))
                }
                onConfirm={() => deleteCard()}
                inProcess={false}
                actionFailed={false}
                title={"Confirmation de suppression"}
                text={"Êtes-vous sûr de bien vouloir supprimer cet élément ?"}
                labelNo={"No"}
                labelYes={"Yes"}
                labelError={"Error"}
              />
            </div>
          }
        </div>
        {Icons()}
      </div>
    </div>
  );
}

export default AddCategory;
