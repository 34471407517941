import axios from "axios";
import { TTP_API_URL } from "../config";
import {
  generateCancellationTokenSource,
  getRequestCancellationToken,
  getRequestConfig,
  throwCatchedError,
} from "utils";

let getTagsCTS;

// export const getTags = ({ token, lng = "fr", filter = null }) => {
//     const requestUrl = `${TTP_API_URL}/blog/tag`;
//     return axios.get(requestUrl, {
//         params: {
//             access_token: token,
//             fields: "id, nameFr, nameEn, nameNl, sanitizedNameFr, parent, isSynonym",
//             filter: JSON.stringify(filter)
//         }
//     });
// };

export const getTags = ({ token, customFilter = null }) => {
  let cancellationTokenSource = generateCancellationTokenSource();

  let requestCancellationToken = getRequestCancellationToken(
    getTagsCTS,
    cancellationTokenSource
  );
  getTagsCTS = cancellationTokenSource;

  const requestUrl = `${TTP_API_URL}/blog/tag`;
  let filter = [];

  if (customFilter !== null) {
    if (Array.isArray(customFilter)) {
      filter.push(...customFilter);
    } else {
      filter.push(customFilter);
    }
  }

  let params = {
    access_token: token,
    nolimit: 1,
    fields:
      "id, nameFr, nameEn, nameNl, sanitizedNameFr, parent, isSynonym, isUniversal",
    filter: JSON.stringify(filter),
  };

  let requestConfig = getRequestConfig(params, requestCancellationToken);
  return axios.get(requestUrl, requestConfig).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};

export const saveTag = (token, data) => {
  const requestUrl = `${TTP_API_URL}/blog/tag`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("nameFr", data.nameFr);
  formData.append("nameNl", data.nameNl);
  formData.append("nameEn", data.nameEn);

  if (data.id) {
    formData.append("id", data.id);
  }

  return axios.post(requestUrl, formData);
};
