import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet } from "react-router-dom";
import CryptoJS from "crypto-js";
import { SubMenu } from "tamtam-components";

import {
  fetchNavCommunity,
  login,
  setNavCommunity,
  setAuthToken,
  setExpiresIn,
  setTokenCreatedAt,
  setAuthUser,
  fetchAuthUser,
  setHeader,
} from "../../store";
import _ from "../../i18n";
import { getCookie, getUserCurrentNavCommunity } from "../../utils";
import { APP_ENV, URL_HASH_KEY } from "../../config";
import { getTTPUser } from "../../api";
import Menu from "./Menu";

// let added = false;

const decryptWithAES = (ciphertext) => {
  var encryptMethod = "AES-256-CBC";
  var aesNumber = encryptMethod.match(/\d+/)[0];
  var encryptMethodLength = parseInt(aesNumber);

  var json = JSON.parse(
    CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(ciphertext))
  );

  var salt = CryptoJS.enc.Hex.parse(json.salt);
  var iv = CryptoJS.enc.Hex.parse(json.iv);

  var encrypted = json.ciphertext; // no need to base64 decode.

  var iterations = parseInt(json.iterations);
  if (iterations <= 0) {
    iterations = 999;
  }
  var encryptMethodLength = encryptMethodLength / 4; // example: AES number is 256 / 4 = 64
  var hashKey = CryptoJS.PBKDF2(URL_HASH_KEY, salt, {
    hasher: CryptoJS.algo.SHA512,
    keySize: encryptMethodLength / 8,
    iterations: iterations,
  });

  var decrypted = CryptoJS.AES.decrypt(encrypted, hashKey, {
    mode: CryptoJS.mode.CBC,
    iv: iv,
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
};

function Layout() {
  const dispatch = useDispatch();
  const showHeader = useSelector((state) => state.params.header);
  const auth = useSelector((state) => state.auth);
  const { user, navCommunity } = auth;
  const [items, setItems] = useState([]);

  useEffect(() => {
    const handleAuthUser = async (user, organizationId) => {
      dispatch(setAuthToken(user.token));
      dispatch(setExpiresIn(user.expiresIn));
      dispatch(setTokenCreatedAt(user.createdAt));

      try {
        const response = await getTTPUser({
          userId: parseInt(user.id),
          token: user.token,
        });
        dispatch(setAuthUser(response.data[0]));
        let currentNavCommunity = getUserCurrentNavCommunity(
          response.data[0],
          organizationId
        );
        if (currentNavCommunity) {
          dispatch(setNavCommunity(currentNavCommunity));
        } else {
          dispatch(fetchNavCommunity());
        }
      } catch (e) {}
    };

    var query = window.location.search.substring(1);
    var vars = query.split("&");

    let queryToken = "";
    let userId = "";
    let organizationId = "";
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === "params") {
        const hashParams = decryptWithAES(decodeURIComponent(pair[1]));
        const tabQueryParams = hashParams.split(",");
        tabQueryParams.forEach((item) => {
          const tmp = item.split("=");
          if (tmp.length > 0) {
            if (tmp[0] === "token") {
              queryToken = tmp[1];
            } else if (tmp[0] === "userId") {
              userId = tmp[1];
            } else if (tmp[0] === "without_header") {
              dispatch(setHeader(false));
            } else if (tmp[0] === "organization_id") {
              organizationId = tmp[1];
            }
          }
        });
      }
    }

    if (queryToken && userId) {
      handleAuthUser(
        {
          token: queryToken,
          createdAt: Date.now(),
          expiresIn: 10000,
          id: userId,
        },
        organizationId
      );
      // const user = {
      //   token: queryToken,
      //   createdAt: Date.now(),
      //   expiresIn: 10000,
      //   id: userId,
      // };
      // dispatch(setAuthToken(user.token));
      // dispatch(setExpiresIn(user.expiresIn));
      // dispatch(setTokenCreatedAt(user.createdAt));
    } else {
      dispatch(login());
    }
  }, []);

  useEffect(() => {
    if (user && !navCommunity) {
      const cookieCommunity = getCookie("ttp_community_" + APP_ENV);
      if (cookieCommunity) {
        let currentNavCommunity = getUserCurrentNavCommunity(
          user,
          cookieCommunity
        );
        if (currentNavCommunity) {
          dispatch(setNavCommunity(currentNavCommunity));
        } else {
          dispatch(fetchNavCommunity());
        }
      } else {
        dispatch(fetchNavCommunity());
      }
    }
  }, [user, navCommunity]);

  // useEffect(() => {
  //   if (auth.user && !auth.navCommunity) {
  //     const cookieCommunity = getCookie("ttp_community_" + APP_ENV);
  //     if (cookieCommunity) {
  //       let currentNavCommunity = getUserCurrentNavCommunity(
  //         auth.user,
  //         cookieCommunity
  //       );
  //       console.log(cookieCommunity);
  //       console.log(currentNavCommunity);
  //       if (currentNavCommunity) {
  //         dispatch(setNavCommunity(currentNavCommunity));
  //       } else {
  //         dispatch(fetchNavCommunity());
  //       }
  //     } else {
  //       dispatch(fetchNavCommunity());
  //     }
  //   }
  // }, [auth.user]);

  // if (
  //   user &&
  //   user.role.typeStatus === "MANAGER" &&
  //   user.role.type === "LEGAL_REPRESENTATIVE" &&
  //   navCommunity?.webtoolsPreferences?.source?.includes("COLLEAGUE")
  // ) {
  //   menu.push({
  //     title: _("library"),
  //     url: `/library`,
  //     iconUrl:
  //       "https://tamtam.s3.eu-west-1.amazonaws.com/cdn/img/icon/header/categories.svg",
  //     community: false,
  //   });
  //   added = true;
  // }

  useEffect(() => {
    let menu = [
      {
        title: _("home"),
        url: `/`,
        iconUrl:
          "https://tamtam.s3.eu-west-1.amazonaws.com/cdn/img/icon/header/home.svg",
        community: false,
      },
    ];
    if (user && navCommunity) {
      if (navCommunity.webtoolsPreferences?.source?.includes("MY_LIBRARY")) {
        menu.push({
          title: _("mySpaces"),
          iconUrl:
            "https://tamtam.s3.eu-west-1.amazonaws.com/cdn/img/icon/header/articles.svg",
          url: "#",
          submenu: [
            {
              title: _("myAdditions"),
              url: "/my-additions",
            },
            {
              title: _("myFavorites"),
              url: "/my-favorites",
            },
          ],
          community: false,
          simple: true,
        });
      }

      if (
        user &&
        user.role.typeStatus === "MANAGER" &&
        user.role.type === "LEGAL_REPRESENTATIVE" &&
        navCommunity.id !== 9 &&
        navCommunity?.webtoolsPreferences?.source?.includes("COLLEAGUE")
      ) {
        menu.push({
          title: _("library"),
          url: `/library`,
          iconUrl:
            "https://tamtam.s3.eu-west-1.amazonaws.com/cdn/img/icon/header/categories.svg",
          community: false,
        });
      }
    }
    setItems(menu);
  }, [user, navCommunity]);

  return !user ? (
    <div className="ripple_loading">
      <img src={"/img/Ripple-1s-150px.svg"} alt="" />
    </div>
  ) : (
    <div>
      {showHeader && <Menu />}
      <SubMenu
        lng="fr"
        menu={items}
        currentCommunity={auth.currentCommunity}
        hideVertical
        RouterLink={Link}
      />
      <div style={{ maxWidth: "1440px", margin: "0 auto" }}>
        <Outlet />
      </div>
    </div>
  );
}

export default Layout;
