import styles from "./ManagementSpace.module.scss";
import IconTrash from "../Icons/IconTrash";
import classnames from "classnames";
import React, { useEffect, useState } from "react";
import IconPen from "../Icons/IconPen";
import { useSelector } from "react-redux";
import { getCategories } from "../../api";
import AddCategory from "./AddCategory";
import { useQuery } from "react-query";
import FilterComponent from "./FilterComponent";

function ManageCategories(props) {
  const params = useSelector((state) => state.params);
  const auth = useSelector((state) => state.auth);
  const [categoryDeleted, setCategoryDeleted] = useState(null);
  const [categoryEdited, setCategoryEdited] = useState(null);
  const [categories, setCategories] = useState(null);

  const [filter, setFilter] = useState({
    lng: params?.language,
    categoryId: null,
    keyWord: "",
  });

  const lng = filter.lng.charAt(0).toUpperCase() + filter.lng.slice(1);

  const fetchAllCategories = async (keyWord = "") => {
    let filter = { search: keyWord, lng: params?.language };
    setCategories(null);
    await getCategories({ token: auth.token, filter }).then((response) => {
      setCategories(response.data.data);
    });
  };

  const categoryQuery = useQuery(
    ["getManageCategories", auth.token],
    async function () {
      const cats = await getCategories({ token: auth.token, filter: {} })
        .then((res) => {
          return res.data.data ? res.data.data : [];
        })
        .catch((error) => {
          return [];
        });
      return cats;
    }
  );

  useEffect(() => {
    if (categoryQuery.data) {
      const data = categoryQuery.data.filter((category) => {
        let selected = true && category[`name${lng}`];
        if (filter.lng && filter.keyWord) {
          selected =
            category[`name${lng}`] !== "" &&
            category[`name${lng}`]
              .toLowerCase()
              .includes(filter.keyWord.toLowerCase());
        }

        return selected;
      });
      setCategories(data);
    }
  }, [categoryQuery.data, filter]);

  let cardsFetching = [];
  for (let j = 0; j < 8; j++) {
    cardsFetching.push(
      <div
        className="cell small-12 medium-4 large-3"
        style={{ padding: "15px 15px 0 0px" }}
      >
        <div key={j} className="card_fetching gradiant" />
      </div>
    );
  }
  return (
    <>
      <AddCategory
        setCategoryDeleted={setCategoryDeleted}
        categoryDeleted={categoryDeleted}
        setCategoryEdited={setCategoryEdited}
        categoryEdited={categoryEdited}
        fetchCategories={fetchAllCategories}
        categories={categories}
      />
      <FilterComponent
        filter={filter}
        updateKeyWordFilter={(e) => {
          setFilter((previousState) => {
            return { ...previousState, keyWord: e.target.value };
          });
        }}
        updateLanguageFilter={(lng) => {
          setFilter((previousState) => {
            return { ...previousState, lng };
          });
        }}
      />
      <div className="grid-x">
        {categoryQuery.isFetching
          ? cardsFetching
          : categories?.map((item) => {
              return (
                <div
                  className="cell small-12 medium-6 large-3"
                  style={{ padding: "20px 15px 0 0px" }}
                >
                  <div className={styles.category_item}>
                    <div
                      className={styles.category_item_icone}
                      style={{
                        backgroundColor: `${
                          item.colorCode ? item.colorCode : "#BA7414"
                        }20`,
                        color: `${item.colorCode ? item.colorCode : "#BA7414"}`,
                      }}
                    >
                      {item.icon ? (
                        <i
                          className={`icon webtool-${item.icon?.label}`}
                          style={{ fontSize: "1.6rem" }}
                        />
                      ) : (
                        <i
                          className={`icon-calculator`}
                          style={{ fontSize: "1.6rem" }}
                        />
                      )}
                    </div>
                    <div className={styles.category_item_infos}>
                      <div className={styles.category_item_title}>
                        {item[`name${lng}`]}
                      </div>
                      {/* <div className={styles.category_item_count}>
                        {item.counter} {"webtools"}
                      </div> */}
                    </div>
                    <div className={styles.category_actions}>
                      <div
                        className={styles.category_action}
                        onClick={() => setCategoryEdited(item)}
                      >
                        <IconPen />
                      </div>
                      {item?.counter === 0 && (
                        <div
                          className={classnames(
                            styles.category_action,
                            styles.category_action_danger
                          )}
                          onClick={() => setCategoryDeleted(item)}
                        >
                          <IconTrash />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
      </div>
    </>
  );
}
export default ManageCategories;
