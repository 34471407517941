import styles from "./ManagementSpace.module.scss";
import classnames from "classnames";
import React, { useEffect, useState } from "react";
import ManageWebtools from "./ManageWebtools";
import ManageCategories from "./ManageCategories";
import { useSelector } from "react-redux";
import { getFFFLibrary, getFFFLibraryRequests, getWebtools } from "../../api";
import _ from "../../i18n";
import FFFLibrary from "./FFFLibrary";
import ManageRequests from "./ManageRequests";
import { useQuery } from "react-query";

function ManagementSpace() {
  const [activeTab, setActiveTab] = useState("WEBTOOLS");

  const [webTools, setWebTools] = useState(null);
  const { token, user, navCommunity } = useSelector((state) => state.auth);
  const params = useSelector((state) => state.params);
  const [filter, setFilter] = useState({
    lng: params?.language,
    categoryId: null,
    keyWord: "",
  });

  // async function fetchCategories(keyWord = "") {
  //   let filter = { search: keyWord, lng: params?.language };
  //   setCategories(null);
  //   await getCategories({ token: token, filter }).then((response) => {
  //     setCategories(response.data.data);
  //   });
  // }

  const proposedRequests = useQuery(
    ["proposedRequests", token, navCommunity],
    async () => {
      if (token && navCommunity) {
        const request = await getFFFLibraryRequests(token, "PENDING");
        return request.data ? request.data.nbResult : 0;
      } else {
        return 0;
      }
    }
  );

  const proposedWebtool = useQuery(
    ["proposedWebtool", token, navCommunity],
    async () => {
      if (token && navCommunity) {
        const request = await await getFFFLibrary({
          token,
          filter: { status: "PROPOSED" },
          workspace: "rss",
        });
        return request.data ? request.data.nbResult : 0;
      } else {
        return 0;
      }
    }
  );

  async function fetchWebTools(categoryId = null, keyWord = "") {
    let filterFetch = {
      categoryId: categoryId,
      search: keyWord,
      lng: filter.lng,
    };
    if (user.role.type !== "LEGAL_REPRESENTATIVE") {
      filterFetch = { ...filterFetch, creator: user.id };
    }
    setWebTools(null);
    await getWebtools({
      token: token,
      community: navCommunity.id,
      filter: filterFetch,
      workspace: "rss",
    })
      .then((response) => {
        setWebTools(response.data.data);
      })
      .catch(function (error) {
        if (error) {
          setWebTools([]);
        }
      });
  }

  // const updateLanguageFilter = (lng) => {
  //   setFilter((previousState) => {
  //     return { ...previousState, lng };
  //   });
  // };

  // const updateKeyWordFilter = (word) => {
  //   setFilter((previousState) => {
  //     return { ...previousState, keyWord: word.target.value };
  //   });
  //   let pastWord = word.target.value;
  //   setTimeout(() => {
  //     if (pastWord === word.target.value) {
  //       fetchWebTools(filter.categoryId, word.target.value);
  //     }
  //   }, 1000);
  // };

  useEffect(() => {
    if (token && navCommunity) {
      fetchWebTools();
    }
  }, [token, navCommunity]);

  useEffect(() => {
    setActiveTab("WEBTOOLS");
  }, [navCommunity]);

  function activeManagementTab(activeTab) {
    setActiveTab(activeTab);
    setFilter((previousState) => {
      return { ...previousState, keyWord: "" };
    });
    fetchWebTools();
  }

  useEffect(() => {
    if (
      navCommunity &&
      !navCommunity.webtoolsPreferences?.source?.includes("MY_LIBRARY")
    ) {
      window.location.href = "/";
    }
  }, [navCommunity]);

  return (
    <div>
      {user?.type === "ADMIN" ? (
        <div className={styles.management_tabs}>
          <div
            className={classnames(
              styles.tab,
              activeTab === "WEBTOOLS" ? styles.active : ""
            )}
            onClick={() => activeManagementTab("WEBTOOLS")}
          >
            <span>{_("webtools")}</span>
          </div>
          <div
            className={classnames(
              styles.tab,
              activeTab === "CATEGORIES" ? styles.active : ""
            )}
            onClick={() => activeManagementTab("CATEGORIES")}
          >
            <span>{_("categories")}</span>
          </div>
          {navCommunity?.id === 9 && (
            <>
              <div
                className={classnames(
                  styles.tab,
                  activeTab === "FFFLIBRARY" ? styles.active : ""
                )}
                onClick={() => activeManagementTab("FFFLIBRARY")}
              >
                <span>{_("Gérer FFF lib")}</span>
                {proposedWebtool.data > 0 && (
                  <span className={styles.tag}> {proposedWebtool.data}</span>
                )}
              </div>
            </>
          )}
          <div
            className={classnames(
              styles.tab,
              activeTab === "REQUESTS" ? styles.active : ""
            )}
            onClick={() => activeManagementTab("REQUESTS")}
          >
            <span>{_("Gérer les demandes")}</span>
            {proposedRequests.data > 0 && navCommunity?.id === 9 && (
              <span className={styles.tag}> {proposedRequests.data}</span>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      <div className={styles.content}>
        {activeTab === "WEBTOOLS" ? (
          <ManageWebtools />
        ) : activeTab === "CATEGORIES" ? (
          <ManageCategories />
        ) : activeTab === "FFFLIBRARY" && navCommunity?.id === 9 ? (
          <FFFLibrary updateTotal={() => proposedWebtool.refetch()} />
        ) : activeTab === "REQUESTS" ? (
          <ManageRequests updateTotal={() => proposedRequests.refetch()} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default ManagementSpace;
