import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { CardTool } from "tamtam-components";
import Tooltip from "../Library/Tooltip";

import _ from "../../i18n";
import IconClose from "../Icons/IconClose";
import { useSelector } from "react-redux";

const WebtoolFilter = ({
  filter,
  setFilter,
  tooltipIsVisible,
  setVisibilityTooltip,
  allowedActions,
  loadingFavoriteIds,
  favoriteIds,
  language,
  handleSaveFavorite,
  setWebtoolEdited,
  setWebtoolDeleted,
  categories,
  organizationWebtools,
  searchedWebtools,
  setSearchedWebtools,
}) => {
  const { user, navCommunity } = useSelector((state) => state.auth);

  useEffect(() => {
    let result = organizationWebtools.filter((element) => {
      let toReturn = true;
      if (filter.categoryId.length) {
        toReturn = filter.categoryId.includes(element.category.id);
      }
      if (toReturn && filter.keyWord) {
        let found = false;
        let languages = filter.lng.length ? filter.lng : ["fr", "en", "nl"];
        for (let i = 0; i < languages.length; i++) {
          const language =
            languages[i].charAt(0).toUpperCase() + languages[i].slice(1);
          found =
            found ||
            element[`title${language}`]
              .toLowerCase()
              .includes(filter.keyWord.toLowerCase());
        }
        toReturn = found;
      }
      if (toReturn && filter.lng.length) {
        let found = false;
        for (let i = 0; i < filter.lng.length; i++) {
          found =
            found || element.languages.includes(filter.lng[i].toUpperCase());
        }
        toReturn = found;
      }
      return toReturn;
    });
    setSearchedWebtools(result);
  }, [organizationWebtools, filter.categoryId, filter.keyWord, filter.lng]);

  const updateLanguageFilter = (lng) => {
    let filtredLng = [];
    if (filter.lng.includes(lng)) {
      filtredLng = filter.lng.filter((item) => item !== lng);
    } else {
      filtredLng = [...filter.lng];
      filtredLng.push(lng);
    }
    setFilter((previousState) => {
      return { ...previousState, lng: filtredLng };
    });
  };

  const updateKeyWordFilter = (word) => {
    setFilter((previousState) => {
      return { ...previousState, keyWord: word.target.value };
    });
  };

  const updateCategoryFilter = (category) => {
    let filtredCategory = [];
    if (filter.categoryId.includes(category.id)) {
      filtredCategory = filter.categoryId.filter(
        (item) => item !== category.id
      );
    } else {
      filtredCategory = [...filter.categoryId];
      filtredCategory.push(category.id);
    }
    setFilter((previousState) => {
      return { ...previousState, categoryId: filtredCategory };
    });
  };
  const renderSearchedWebtoolsFrame = () => {
    let cardsFetching = [];
    for (let j = 0; j < 8; j++) {
      cardsFetching.push(
        <div key={j} className="card_tool_fetching gradiant" />
      );
    }
    return (
      <div className="search_frame">
        <div className="search_frame_title">
          <h3>{_("searchResults")}</h3>
          <div
            className="action"
            onClick={() =>
              setFilter(() => {
                return { lng: [], categoryId: [], keyWord: "" };
              })
            }
          >
            <span>{_("cancel")}</span> <IconClose size={9} />
          </div>
        </div>
        <div className="grid-x category_items">
          {searchedWebtools.length === 0 ? (
            <div className="no-data-found">{_("noDataFound")}</div>
          ) : (
            searchedWebtools.map((item, i) => {
              const organizationName =
                item.organizationSource && item.organizationSource.abbreviation
                  ? item.organizationSource.abbreviation
                  : item.organizationSource && item.organizationSource.name
                  ? item.organizationSource.name
                  : item.organization.abbreviation
                  ? item.organization.abbreviation
                  : item.organization.name;
              return (
                <div
                  className="cell small-12 medium-4 large-3 grid_card"
                  key={`swt-${item.id}`}
                >
                  <CardTool
                    toolContent={
                      <Tooltip
                        webtool={item}
                        onSavedWebtool={setVisibilityTooltip}
                      />
                    }
                    displayTooltip={tooltipIsVisible === item.id}
                    setTooltipVisibility={(element) => {
                      setVisibilityTooltip(element ? item.id : null);
                    }}
                    allowedActions={{
                      favorite: true,
                      update:
                        (allowedActions || item.creator.id === user?.id) &&
                        item.organizationSource &&
                        navCommunity.id !== 9,
                    }}
                    loadingActions={{
                      favorite: loadingFavoriteIds.includes(item.id),
                    }}
                    isFavorite={favoriteIds.includes(item.id)}
                    data={{
                      id: item.id,
                      icon: item.icon.label,
                      color: item.category.colorCode,
                      link: item[`urlName${language}`],
                      title: item[`title${language}`],
                      description: item[`description${language}`] ?? "",
                      tag: item.category[`name${language}`],
                      scope: item.csScope.includes("PUBLIC")
                        ? "public"
                        : "shared",
                      created: item.createdAt
                        ? `${_("createdThe")} ${item.createdAt}`
                        : "",
                      organization: organizationName
                        ? `${_("by")} ${organizationName}`
                        : "",
                      source: item.organizationSource ? _("libraryFFF") : null,
                    }}
                    color={item.category.colorCode}
                    onReach={() =>
                      window.open(item[`urlName${language}`], "_blank")
                    }
                    onUpdate={() => setWebtoolEdited(item)}
                    onDelete={() => setWebtoolDeleted(item)}
                    onAddFavorite={() => handleSaveFavorite(item)}
                  />
                </div>
              );
            })
          )}
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="filter">
        <div className="search_frame grid-x align-center">
          <div className="search cell small-12 medium-5">
            <i className="icon icon-magnifier" />
            <input
              id="searchInput"
              autoComplete="off"
              type="text"
              value={filter.keyWord}
              placeholder={`${_("search")}...`}
              onChange={(event) => updateKeyWordFilter(event)}
            />
          </div>
          {["fr", "en", "nl"].map((item, i) => {
            return (
              <div
                key={item}
                className={classnames(
                  "lng_item",
                  filter.lng.includes(item) ? "activated" : ""
                )}
                onClick={() => updateLanguageFilter(item)}
              >
                {item}
              </div>
            );
          })}
        </div>
        <div className="tags grid-x align-center">
          {categories.map((item, i) => {
            return (
              <div
                className={classnames(
                  "tag_item",
                  filter.categoryId.includes(item.id) ? "activated" : ""
                )}
                onClick={() => updateCategoryFilter(item)}
                key={`fcat-${item.id}`}
              >
                {item[`name${language}`]}
              </div>
            );
          })}
        </div>
      </div>
      {(filter.categoryId.length > 0 ||
        filter.keyWord !== "" ||
        filter.lng.length > 0) &&
        renderSearchedWebtoolsFrame()}
    </>
  );
};

export default WebtoolFilter;
