import React from "react";
import Select from "react-select";
import { useQuery } from "react-query";
import { sortCategoriesAlphabetically } from "utils";
import { getCategories } from "api";
import { useSelector } from "react-redux";
import _ from "i18n";

export default function Category({
  selectStyles,
  language,
  category,
  setCategory,
}) {
  const { token } = useSelector((state) => state.auth);

  const nameAttr = `name${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;

  const customFilter = (option, searchText) => {
    return option.data.name.toLowerCase().includes(searchText.toLowerCase());
  };

  const { data: categories, isFetching: loadingCategories } = useQuery(
    ["getCategories", token],
    async () => {
      if (token) {
        try {
          const res = await getCategories({ token, filter: { lng: language } });
          let categories = sortCategoriesAlphabetically(
            res.data.data,
            nameAttr
          );

          return categories.map((category, index) => {
            const categoryName =
              category[nameAttr] ||
              category["nameFr"] ||
              category["nameEn"] ||
              category["nameNl"];
            return {
              id: category.id,
              name: categoryName,
            };
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
  );

  return (
    <Select
      classNamePrefix="react-select"
      styles={selectStyles}
      isLoading={loadingCategories}
      options={categories}
      filterOption={customFilter}
      placeholder={_("select_category")}
      value={category}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      onChange={setCategory}
    />
  );
}
