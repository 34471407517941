/* eslint-disable default-case */
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Loader from "../Commun/Loader";
import Button from "../Commun/Button";
import _ from "../../i18n";
import { setAddToLibrary } from "../../api";

import GroupsSelect from "./GroupsSelect";
import CollaboratorsSelect from "./CollaboratorsSelect";
import ClientSelect from "./ClientSelect";
import ContactSelect from "./ContactSelect";
import styles from "./Tooltip.module.scss";
import { SCOPES } from "../../config";

const Tooltip = ({ webtool, isSharedWebtool, onSavedWebtool }) => {
  const token = useSelector((state) => state.auth.token);
  const { navCommunity } = useSelector((state) => state.auth);

  const [saving, setSaving] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [specCollaborators, setSpecCollaborators] = useState([]);
  const [specClients, setSpecClients] = useState([]);
  const [specContacts, setSpecContacts] = useState([]);

  const dispatch = useDispatch();

  // const scopes = [
  //   "PUBLIC",
  //   "ALL_COLLABORATORS",
  //   "ALL_CLIENTS",
  //   "ALL_CONTACTS",
  //   "SPEC_COLLABORATOR",
  //   "SPEC_CLIENT",
  //   "SPEC_CONTACT",
  //   "GROUP",
  // ];

  useEffect(() => {
    if (isSharedWebtool) {
      setCheckedItems(webtool.csScope.split(","));
      if (webtool.specClients) {
        setSpecClients(webtool.specClients);
      }
      if (webtool.specCollaborators) {
        setSpecCollaborators(webtool.specCollaborators);
      }
      if (webtool.specContacts) {
        setSpecContacts(webtool.specContacts);
      }
      setSelectedGroups(
        webtool?.groups && webtool.groups !== "" ? webtool.groups : []
      );
    } else {
      setCheckedItems(["PUBLIC"]);
    }
  }, []);

  const handleChange = (scope) => {
    let tab = [];
    switch (scope) {
      case "PUBLIC":
        tab = ["PUBLIC"];
        setSpecCollaborators([]);
        setSpecClients([]);
        setSpecContacts([]);
        break;
      case "ALL_COLLABORATORS":
        if (checkedItems.includes("ALL_COLLABORATORS")) {
          tab = [...checkedItems.filter((el) => el !== "ALL_COLLABORATORS")];
        } else {
          tab = [
            ...checkedItems.filter(
              (el) => el !== "PUBLIC" && el !== "SPEC_COLLABORATOR"
            ),
            "ALL_COLLABORATORS",
          ];
          setSpecCollaborators([]);
        }
        break;
      case "ALL_CLIENTS":
        if (checkedItems.includes("ALL_CLIENTS")) {
          tab = [...checkedItems.filter((el) => el !== "ALL_CLIENTS")];
        } else {
          tab = [
            ...checkedItems.filter(
              (el) => el !== "PUBLIC" && el !== "SPEC_CLIENT"
            ),
            "ALL_CLIENTS",
          ];
          setSpecClients([]);
        }
        break;
      case "ALL_CONTACTS":
        if (checkedItems.includes("ALL_CONTACTS")) {
          tab = [...checkedItems.filter((el) => el !== "ALL_CONTACTS")];
        } else {
          tab = [
            ...checkedItems.filter(
              (el) => el !== "PUBLIC" && el !== "SPEC_CONTACT"
            ),
            "ALL_CONTACTS",
          ];
          setSpecContacts([]);
        }
        break;
      case "SPEC_COLLABORATOR":
        if (checkedItems.includes("SPEC_COLLABORATOR")) {
          tab = [...checkedItems.filter((el) => el !== "SPEC_COLLABORATOR")];
          setSpecCollaborators([]);
        } else {
          tab = [
            ...checkedItems.filter(
              (el) => el !== "PUBLIC" && el !== "ALL_COLLABORATORS"
            ),
            "SPEC_COLLABORATOR",
          ];
        }
        break;
      case "SPEC_CLIENT":
        if (checkedItems.includes("SPEC_CLIENT")) {
          tab = [...checkedItems.filter((el) => el !== "SPEC_CLIENT")];
          setSpecClients([]);
        } else {
          tab = [
            ...checkedItems.filter(
              (el) => el !== "PUBLIC" && el !== "ALL_CLIENTS"
            ),
            "SPEC_CLIENT",
          ];
        }
        break;
      case "SPEC_CONTACT":
        if (checkedItems.includes("SPEC_CONTACT")) {
          tab = [...checkedItems.filter((el) => el !== "SPEC_CONTACT")];
          setSpecContacts([]);
        } else {
          tab = [
            ...checkedItems.filter(
              (el) => el !== "PUBLIC" && el !== "ALL_CONTACTS"
            ),
            "SPEC_CONTACT",
          ];
        }
        break;
      case "GROUP":
        if (checkedItems.includes("GROUP")) {
          tab = [...checkedItems.filter((el) => el !== "GROUP")];
          setSelectedGroups([]);
        } else {
          tab = [
            ...checkedItems.filter((el) => el !== "PUBLIC" && el !== "GROUP"),
            "GROUP",
          ];
        }
        break;
    }
    if (tab.length === 0) {
      tab = ["PUBLIC"];
    }
    setCheckedItems(tab);
  };

  const saveShare = () => {
    setSaving(true);
    setAddToLibrary({
      token,
      webtool: webtool.id,
      owner: webtool.organization.id,
      organization: navCommunity.id,
      groups: selectedGroups.map((group) => group.id),
      specCollaborators: specCollaborators.map((el) => el.id),
      specClients: specClients.map((el) => el.id),
      specContacts: specContacts.map((el) => el.id),
      csScope: checkedItems,
      sharedId: webtool.articleSharingId,
    })
      .then((response) => {
        setSaving(false);
        dispatch(
          onSavedWebtool({
            articleSharingId: response.data.data.id,
            ...webtool,
          })
        );
      })
      .catch((err) => {
        setSaving(false);
      });
  };

  const updateShare = () => {
    let scope = [];
    Object.keys(checkedItems).forEach(function (key) {
      if (key === "GROUP") {
        if (checkedItems[key] && selectedGroups.length > 0) scope.push(key);
      } else {
        if (checkedItems[key]) scope.push(key);
      }
    });
    setSaving(true);
    setAddToLibrary({
      token,
      // webtool: webtool.id,
      // owner: webtool.organization.id,
      // organization: navCommunity.id,
      // groups: selectedGroups.map((group) => group.id),
      csScope: checkedItems,
      // specCollaborators: specCollaborators.map((el) => el.id),
      // specClients: specClients.map((el) => el.id),
      // specContacts: specContacts.map((el) => el.id),
      sharedId: webtool.articleSharingId,
    })
      .then((response) => {
        dispatch(
          onSavedWebtool({
            articleSharingId: response.data.data.id,
            ...webtool,
          })
        );
        setSaving(false);
      })
      .catch((err) => {
        setSaving(false);
      });
  };

  return (
    <div className={styles.container} onClick={(e) => e.stopPropagation()}>
      <div className={styles.share__btn}>
        <span className={styles.title}>{_("share_with")}:</span>
        <ul>
          {SCOPES.map((scope) => (
            <li key={`scope-${scope}`}>
              <label>
                <input
                  name={scope}
                  type="checkbox"
                  onChange={(e) => handleChange(e.target.value)}
                  checked={checkedItems.includes(scope)}
                  value={scope}
                />
                &nbsp; {_(`${scope}`)}
              </label>
              {scope === "SPEC_COLLABORATOR" &&
                checkedItems.includes("SPEC_COLLABORATOR") && (
                  <CollaboratorsSelect
                    isMulti={true}
                    selectedCollaborators={specCollaborators}
                    onChange={setSpecCollaborators}
                    organizationId={navCommunity.id}
                  />
                )}
              {scope === "SPEC_CLIENT" &&
                checkedItems.includes("SPEC_CLIENT") && (
                  <ClientSelect
                    isMulti={true}
                    selectedClients={specClients}
                    onChange={setSpecClients}
                  />
                )}
              {scope === "SPEC_CONTACT" &&
                checkedItems.includes("SPEC_CONTACT") && (
                  <ContactSelect
                    isMulti={true}
                    selectedContacts={specContacts}
                    onChange={setSpecContacts}
                    organizationId={navCommunity.id}
                  />
                )}
              {scope === "GROUP" && checkedItems.includes("GROUP") && (
                <GroupsSelect
                  isMulti={true}
                  selectedGroups={selectedGroups}
                  onChange={setSelectedGroups}
                  organizationId={navCommunity.id}
                />
              )}
            </li>
          ))}
        </ul>

        <Button
          disabled={saving}
          onClick={() => {
            webtool.organizationArticleId ? updateShare() : saveShare();
          }}
        >
          {saving ? (
            <Loader
              style={{
                height: "10px",
              }}
              color={"#fff"}
            />
          ) : (
            _("share")
          )}
        </Button>
      </div>
    </div>
  );
};

export default Tooltip;
