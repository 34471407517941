const faqNl = {
  title: "Titel",
  description: "Beschrijving",
  tags: "Labels",
  category: "Categorie",
  save: "Sparen",
  update: "Bijwerken",
  cancel: "Annuleren",
  select: "selecteer",
  icon: "icoon",
  managementSpace: "management ruimte",

  webToolSaved: "webtool succesvol opgeslagen",
  webToolUpdated: "webtool succesvol bijgewerkt",
  webToolDeleted: "webtool succesvol verwijderd",
  categorySaved: "categorie succesvol opgeslagen",
  categoryUpdated: "categorie succesvol bijgewerkt",
  categoryDeleted: "categorie succesvol verwijderd",

  myAdditions: "mijn aanvullingen",
  myFavorites: "mijn favorieten",
  mySpaces: "mijn ruimtes",

  //Home Page
  allWebToolsOf: "All webtools powered by",
  scrollSelection: "scroll the selection",
  seeMore: "see more",
  sharedWithMe: "Shared with me recently",
  recommandedBy: "recommanded by",
  my: "My",
  webtools: "webtools",
  categories: "categories",
  addWebtool: "add a webtool",
  theMost: "the most",
  popular: "Popular",
  searchResults: "Search results:",

  PUBLIC: "Public",
  ALL_COLLABORATORS: "All collaborators",
  ALL_CLIENTS: "All clients",
  ALL_CONTACTS: "All contacts",
  SPEC_COLLABORATOR: "Specific collaborators",
  SPEC_CLIENT: "Specific clients",
  SPEC_CONTACT: "Specific contact",
  GROUP: "Group",

  saveSuccess: "updated succesfully",
  shareSuccess: "shared succesfully",
  savingError: "Error",

  // Library Page
  webtoolsToShare: "webtools to share",
  library: "Library",
  myLibrary: "my library",
  subHeaderLibrary: "share webtools with your collaborators or clients",
  allScopes: "All scopes",
  createdThe: "created the",
  by: "By",
  confirmDelete: "Delete confirmation",
  confirmDeleteMessage: "Are you sure to delete this item ?",
  no: "no",
  yes: "yes",
  noDataFound: "no data found",

  pageNotFound: "This Page Could Not Be Found!",
  getLost: "You've moved so fast and got lost...  ",

  // modal
  delete_confirmation_title: "Deletion confirmation",
  delete_confirmation_text: "Are you sure you want to delete this item ?",
  error: "Error",

  share_with: "Share with",

  search: "Search",

  sharedWith: "Share with",

  PUBLIC_SCOPE: "Public",
  ALL_COLLABORATORS_SCOPE: "Collaborators",
  ALL_CLIENTS_SCOPE: "Customers",
  ALL_CONTACTS_SCOPE: "Contacts",
  SPEC_COLLABORATOR_SCOPE: "Spec Collabs",
  SPEC_CLIENT_SCOPE: "Spec Clients",
  SPEC_CONTACT_SCOPE: "Spec Contact",
  GROUP_SCOPE: "Group",

  chain: "Chain",
  chain_title: "Chain title",
  authors: "Authors",
  author_title: "Author title",
  author_headline: "Author headline",
  add_chain: "Add chain",
  loading_suggestions: "Loading...",
  type_chain: "Type the chain name",
  type_author: "Type the author name",
  our_colleagues: "Our colleagues",
  webtools_shared_by_colleague: "Webtools shared by colleague",
  webtools_rejected: "Webtools rejected",
  data_saved_successfully: "Data saved successfully",
  draf: "Draft",
  published: "Published",
  disabled: "Disabled",
  select_category: "Select category",
  select_tags: "Select tags",
  select_theme: "Select theme",
  select_page: "Select page",
  confirmAccept: "Publication confirmation",
  confirmAcceptMessage: "Do you want to accept this item into the FFF library?",
  confirmReject: "Rejection confirmation",
  confirmRejectMessage: "Are you sure you want to reject this proposal?",
  no_request_found: "No request found",
  libraryFFF: "FFF Library",
  errors: "Errors",
  title_required: "Title is required",
  url_required: "Url is required",
  description_required: "Description is required",
  icon_required: "Icon is required",
  invalid_category: "Invalid category",
  invalid_theme: "Invalid theme",
  invalid_page: "Invalid page",
  ourColleagues: "Our Colleagues",
  home_fiduciary_desc:
    "Reference your links in the Webtools sharing tool and thus contribute to strengthening the fraternal dynamic within the United Associates community.",
  home_fff_desc: "",
  home_colleagues_desc: "",
  my_webtools: "My Webtools",
  shared_webtools: "Shared Webtools",
};
export default faqNl;
