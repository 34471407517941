import classNames from "classnames";
import React, { PureComponent } from "react";

import styles from "./MultiSwitch.module.scss";

export default class MultiSwitchComponent extends PureComponent {
  static defaultProps = {
    title: "",
  };

  render() {
    let { labels, vals, name, onChange, selectedOption, title } = this.props;

    if (!labels || labels.length === 0) {
      return null;
    }

    let returnDiv = [];
    for (let i = 0; i < labels.length; i++) {
      returnDiv.push(
        <li key={`switch-${vals[i]}`} className={styles.item}>
          <span className={styles.itemLabel}>{labels[i]}</span>
          <div className="switch-container">
            <label>
              <input
                value={vals[i]}
                name={name}
                checked={selectedOption === vals[i]}
                onChange={(e) => {
                  onChange(e);
                }}
                className={classNames(
                  styles.switch,
                  selectedOption === vals[i] ? styles.checked : ""
                )}
                type="radio"
              />
              <div className="switch-frame">
                <div className="switch-handle" />
              </div>
            </label>
          </div>
        </li>
      );
    }

    return (
      <div className={styles.switches}>
        {title && <strong>{title}</strong>}
        <ul>{returnDiv}</ul>
      </div>
    );
  }
}
