import React from "react";
import { useSelector } from "react-redux";
import AsyncSelect from "react-select/async";

import { getGroups } from "../../api";

export default function GroupsSelect({
  isMulti,
  selectedGroups,
  organizationId,
  onChange,
}) {
  const token = useSelector((state) => state.auth.token);

  const fetchGroups = (inputValue) => {
    let customFilter = [];

    if (null !== inputValue) {
      customFilter = [
        {
          property: "name",
          value: inputValue,
          operator: "like",
        },
      ];
    }

    return getGroups({
      token,
      clientId: organizationId,
      customFilter,
    }).then((result) => {
      return result.data.data;
    });
  };

  return (
    <div>
      <AsyncSelect
        isMulti={isMulti}
        cacheOptions
        value={selectedGroups}
        onChange={(e) => onChange(e)}
        loadOptions={fetchGroups}
        defaultOptions={true}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        classNamePrefix="custom-select"
      />
    </div>
  );
}
