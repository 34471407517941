import React from "react";
import { addLandaSize } from "utils";

import styles from "./Chain.module.scss";
import IconClose from "components/Icons/IconClose";

const Chain = (props) => {
  const { author, language, onChange, onDelete } = props;

  const handleDeleteAuthor = (e) => {
    e.stopPropagation();
    onDelete(author);
  };

  const renderAvatar = () => {
    const { enableAvatar, avatarUrl } = author;

    if (!avatarUrl) {
      return (
        <div
          className={`${styles.sidebarAvatar} ${styles.emptyAvatar}`}
          style={{ backgroundImage: `url("/img/user-avatar.svg")` }}
        >
          <div
            className={styles.avatarOverlay}
            style={
              enableAvatar === true && enableAvatar !== "D"
                ? { display: "none" }
                : { display: "block" }
            }
          ></div>
        </div>
      );
    }

    return (
      <div
        className={styles.sidebarAvatar}
        style={{ backgroundImage: `url(${addLandaSize(avatarUrl, 240)})` }}
      >
        <div
          style={
            enableAvatar === true && enableAvatar !== "D"
              ? { display: "none" }
              : { display: "block" }
          }
        ></div>
      </div>
    );
  };

  const getAuthorHeadline = (author, language, currentHead) => {
    const { headlines } = author;

    if (headlines && headlines[language]) {
      return headlines[language];
    } else {
      return currentHead;
    }
  };

  const nameAttr = `name${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;
  const title =
    author[nameAttr] ||
    author["nameFr"] ||
    author["nameEn"] ||
    author["nameNl"];
  // useEffect(() => {
  //   if (props.language !== language) {
  //     const { signature } = author;
  //     if (author && signature) {
  //       const { title, head } = signature;
  //       const newHead = getAuthorHeadline(author, language, head);

  //       onChange({ ...author, signature: { head: newHead, title } });
  //     }
  //   }
  // }, [author, language, onChange, props.language]);

  return (
    <div className={styles.sidebarAuthor}>
      <div className={styles.authorRemove} onClick={handleDeleteAuthor}>
        <IconClose />
      </div>

      {renderAvatar()}

      <div className={`${styles.sidebarUsername} ${styles.editableInput}`}>
        <input type="text" readOnly={true} value={title} />
      </div>
    </div>
  );
};

export default Chain;
