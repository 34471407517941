import * as error404Data from "./lottie-animations/nothing-found.json";
import styles from "./NotFound.local.scss";
import Lottie from "react-lottie";
import _ from "../../../i18n";

function NotFound() {
  let defaultOptions = {
    loop: true,
    autoplay: true,
  };
  return (
    <div className={styles.not_found}>
      <Lottie
        options={{ ...defaultOptions, animationData: error404Data }}
        height={360}
        isClickToPauseDisabled={true}
      />
      <h2>{_("Oops")}</h2>
      <p>{_("NOTHING FOUND")}</p>
    </div>
  );
}

export default NotFound;
