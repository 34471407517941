import { getWebtoolIcons } from "api";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import styles from "./IconsModal.module.scss";
import { CloseOutlined } from "@ant-design/icons";
import IconClose from "components/Icons/IconClose";

const IconsModal = ({ uploadClicked, setUploadIsClicked, setSelectedIcon }) => {
  const { token } = useSelector((state) => state.auth);
  const { language } = useSelector((state) => state.params);
  const [webToolsIcons, setWebToolsIcons] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (token) {
      getWebtoolIcons(token).then((response) => {
        if (response.data.data && response.data.data.length > 0) {
          setWebToolsIcons(response.data.data);
        }
      });
    }
  }, [token]);

  const titleAttr = `title${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;
  const nameAttr = `name${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;

  const filteredItems = webToolsIcons.filter((item) => {
    return (
      (item[titleAttr] &&
        item[titleAttr].toLowerCase().includes(searchValue.toLowerCase())) ||
      (item.label &&
        item.label.toLowerCase().includes(searchValue.toLowerCase())) ||
      (item.tags &&
        item.tags.some(
          (tag) =>
            tag[nameAttr] &&
            tag[nameAttr].toLowerCase().includes(searchValue.toLowerCase())
        ))
    );
  });
  return (
    <div
      className={classnames(
        styles.add_modal_icons,
        uploadClicked ? styles.active : ""
      )}
    >
      <div className={styles.modal_icons_content}>
        <div className={styles.close} onClick={() => setUploadIsClicked(false)}>
          <IconClose />
        </div>

        <div
          className={classnames(
            " grid-x grid-margin-x",
            styles.filter_icons_container
          )}
        >
          <div className={classnames(styles.filter_icons, `cell small-12`)}>
            <i className="icon icon-magnifier" />
            <input
              type="text"
              value={searchValue}
              onChange={({ target }) => setSearchValue(target.value)}
              //  disabled={webtoolIcons.fetching}
              placeholder={"Search..."}
            />
          </div>
        </div>
        <div
          className="grid-x"
          style={{ padding: "20px 20px 0 20px", justifyContent: "center" }}
        >
          {filteredItems.map((item) => (
            <li
              key={item.id}
              id={`webtool-icon-${item.id}`}
              className={classnames(
                "cell small-12 medium-4 large-3",
                styles.icons_item
              )}
              //   className="card-item cell small-6 medium-3"
              onClick={() => {
                setSelectedIcon(item);
                setUploadIsClicked(false);
              }}
            >
              <i className={`icon webtool-${item.label}`} />
              <span title={item[titleAttr] || item.label}>
                {(item[titleAttr].length < 17
                  ? item[titleAttr]
                  : item[titleAttr].substring(0, 14) + "...") ||
                  (item.label.length < 16
                    ? item.label
                    : item.label.substring(0, 13) + "...")}
              </span>
            </li>
          ))}
        </div>
      </div>
    </div>
  );
};

export default IconsModal;
