import _ from "../i18n";

export const APP_ENV = process.env.REACT_APP_APP_ENV;
export const TTP_API_URL = process.env.REACT_APP_TTP_API_URL;
export const TTP_HOME_URL = process.env.REACT_APP_TTP_HOME_URL;
export const TTP_EMAILING_URL = process.env.REACT_APP_TTP_EMAILING_URL;
export const TTP_WEBTOOL_URL = process.env.REACT_APP_TTP_WEBTOOL_URL;

export const S3_FOLDER_URL = `https://s3.tamtam.pro/v2`;

export const UA_DOMAIN_NAME = `unitedassociates.be`;

export const AUTH_COOKIE_NAME = `ttp_auth_${APP_ENV}`;

export const API_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

export const URL_HASH_KEY = "Y3YZVA6uSLVqJhAWHdsb9CsGgcBXIhmY";

export const CLIENT_CREDENTIAL = {
  grant_type: "client_credentials",
  client_id: "10011",
  client_secret: "BlogSecretS#K$",
  scope: "ttp",
};

export const LANG_INDEX = ["fr", "en", "nl"];
export const LANGUAGES = [
  { value: "fr", label: _("french") },
  { value: "nl", label: _("dutch") },
  { value: "en", label: _("english") },
];
export const LANG_TRANS = {
  fr: _("french"),
  en: _("english"),
  nl: _("dutch"),
};

export const SHARING_OPTIONS = [
  { label: _("public"), value: "PUBLIC" },
  { label: _("clients"), value: "ALL_CLIENTS" },
  { label: _("collaborators"), value: "ALL_COLLABORATORS" },
  { label: _("groups"), value: "ALL_GROUPS" },
];

export const SCOPES = [
  "PUBLIC",
  "ALL_COLLABORATORS",
  "ALL_CLIENTS",
  "ALL_CONTACTS",
  "SPEC_COLLABORATOR",
  "SPEC_CLIENT",
  "SPEC_CONTACT",
  "GROUP",
];

export const SELECT_STYLES = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled ? "#e6e6e6" : "#F8F9FA",
    boxShadow: "none",
    border: state.isFocused ? "1px solid #2495E1" : "1px solid #B2BCC6",
    "&:hover": {
      borderColor: state.isFocused ? "#18A0FB" : "#B2BCC6",
    },
    padding: 0,
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    lineHeight: "14px",
    color: "#6D7F92",
    fontWeight: 400,
  }),
  menuList: (provided, state) => ({
    ...provided,
    paddingTop: "0",
    paddingBottom: "0",
  }),
  menu: (provided, state) => ({
    ...provided,
    borderRadius: "5px",
    backgroundColor: "#FFFFFF",
    overflow: "hidden",
    zIndex: 2,
  }),
  option: (provided, state) => ({
    ...provided,
    textAlign: "left",
    fontSize: "12px",
    lineHeight: "14px",
  }),
  multiValue: (provided, { data }) => ({
    ...provided,
    backgroundColor: data.color ? data.color : "#F1F2F4",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    fontSize: ".75rem",
    textTransform: "uppercase",
    color: "inherit",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#29394D",
  }),
};
