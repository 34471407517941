import React from "react";
import styles from "./Switch.module.scss";

const Switch = ({ name, checked, disabled, onChange }) => {
  return (
    <div className="switch-container">
      <label>
        <input
          name={name}
          checked={checked}
          onChange={onChange}
          className={styles["ttp-switch"]}
          type="checkbox"
          disabled={disabled ? "disabled" : ""}
        />
        <div>
          <span>
            <span className="icon icon-toolbar grid-view" />
          </span>
          <span>
            <span className="icon icon-toolbar ticket-view" />
          </span>
          <div />
        </div>
      </label>
    </div>
  );
};

export default Switch;
