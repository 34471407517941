import { createSlice } from "@reduxjs/toolkit";

import { getDefaultLanguage } from "../utils";

const defaultLanguage = localStorage.getItem("lng") || getDefaultLanguage();
const initialState = {
  language: defaultLanguage,
  header: true,
};

const paramsSlice = createSlice({
  name: "params",
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
      localStorage.setItem("lng", action.payload);
    },
    setHeader: (state, action) => {
      state.header = action.payload;
    },
  },
});

export const { setLanguage, setHeader } = paramsSlice.actions;

export default paramsSlice.reducer;
