import React from "react";

const SimpleArrowDownIcon = ({ width = 16, height = 10, fill = "#29394D" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.73527 0.98527C1.15834 0.562202 1.84427 0.562202 2.26733 0.98527L8.0013 6.71924L13.7353 0.98527C14.1583 0.562202 14.8443 0.562202 15.2673 0.98527C15.6904 1.40834 15.6904 2.09427 15.2673 2.51733L8.76733 9.01733C8.34427 9.4404 7.65834 9.4404 7.23527 9.01733L0.73527 2.51733C0.312202 2.09427 0.312202 1.40834 0.73527 0.98527Z"
      fill={fill}
    />
  </svg>
);

export default SimpleArrowDownIcon;
