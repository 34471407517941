import React from "react";

import styles from "./SideBar.module.scss";
import IconClose from "../../../Icons/IconClose";
import classNames from "classnames";

const SideBar = ({ children, title, isOpen, onClose }) => {
  // useEffect(() => {
  //   const allElements = document.querySelectorAll("*");
  //   const headerLeftElements = Array.from(allElements).filter((element) => {
  //     return element.classList && Array.from(element.classList).some((className) => className.includes("headerLeft"));
  //   });
  //   const widgetElements = Array.from(allElements).filter((element) => {
  //     return element.classList && Array.from(element.classList).some((className) => className.includes("widgets_btn"));
  //   });

  //   if (isOpen) {
  //     headerLeftElements[0].style.zIndex = 100;
  //     widgetElements.map((el) => (el.style.display = "none"));
  //   } else {
  //     setTimeout(() => {
  //       headerLeftElements[0].style.zIndex = 105;
  //       widgetElements.map((el) => (el.style.display = "flex"));
  //     }, 700);
  //   }
  // }, [isOpen]);

  return (
    <>
      <div
        className={classNames(styles.mask, isOpen ? styles.display : "")}
        onClick={onClose}
      ></div>
      <div className={classNames(styles.sideBar, isOpen ? styles.display : "")}>
        <div className={styles.top}>
          <div className={styles.title}>{title}</div>
          <div className={styles.close} onClick={onClose}>
            <IconClose />
          </div>
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    </>
  );
};

export default SideBar;
